<template>
  <SimpleBar data-simplebar-auto-hide="false" class="content">
    <table>
      <thead>
        <tr class="header">
          <th style="width: 40px">
            #
          </th>
          <th style="width: 100%; text-align: left; overflow-wrap: unset">
            Address
          </th>
          <th style="width: 60px">
            Count
          </th>
          <th style="width: 60px">
            %
          </th>
          <th style="width: 100px">
            Last active
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="({ amount, percentage, lastActive, address }, i) in holdersView" :key="`h${i}`">
          <td># {{ i + 1 }}</td>
          <td
            style="
              text-transform: unset;
              text-align: left;
              font-family: 'Courier New', Courier, monospace;
              overflow-wrap: unset;
            "
          >
            {{ address }}
          </td>
          <td>{{ amount }}</td>
          <td>{{ percentage }}%</td>
          <td>{{ lastActive }}</td>
        </tr>
      </tbody>
    </table>
  </SimpleBar>
</template>

<script>
import BN from 'bignumber.js'
import { getDateStringFromDate } from '../services/dateHelpers'
import { SimpleBar } from 'simplebar-vue3'

export default {
  components: {
    SimpleBar,
  },
  props: ['holders'],

  computed: {
    holdersView() {
      return this.holders
        .map(
          ({
            address,
            quantity,
            percentage,
            first_incoming_transaction,
            last_outgoing_transaction,
            is_vault,
            type,
          }) => {
            let displayAddress = address
            if (is_vault) displayAddress = `[vault] ${address}`
            else if (type === 'dispenser') displayAddress = `[dispenser] ${address}`
            else if (!last_outgoing_transaction) displayAddress = `[no debit] ${address}`

            const lastActivityDate = last_outgoing_transaction || first_incoming_transaction

            return {
              address: displayAddress,
              amount: Number(quantity),
              percentage: is_vault ? percentage : percentage,
              lastActive: lastActivityDate ? getDateStringFromDate(new Date(lastActivityDate)) : '-',
            }
          },
        )
        .sort((a, b) => (new BN(b.amount).gt(a.amount) ? 0 : -1))
    },
  },
}
</script>
