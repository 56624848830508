<template>
  <div id="available_assets">
    <h3>
      <AssetsIcon />
      Available assets
    </h3>
    <SimpleBar
      data-simplebar-auto-hide="false"
      class="content"
    >
      <table>
        <thead>
          <tr class="header">
            <th>Type</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(listing, i) in listings"
            :key="`listing${i}`"
          >
            <td>{{ getListingType(listing) }}</td>
            <td>
              <span class="price">{{ getOriginalPrice(listing) }}</span> ({{ getConvertedPrice(listing) }})
            </td>
            <td>{{ listing.quantity }}</td>
            <td>
              <a
                class="link"
                :href="listing.getUrl()"
                target="_blank"
              >
                <LinkIcon />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </SimpleBar>
  </div>
</template>

<script>
import Markets from '../enums/Markets.js'
import Price from '../models/Price'

import AssetsIcon from './icons/AssetsIcon.vue'
import LinkIcon from './icons/LinkIcon.vue'
import { SimpleBar } from 'simplebar-vue3'

import { useApp } from '../composables/useApp'

export default {

  components: {
    AssetsIcon,
    LinkIcon,
    SimpleBar,
  },
  props: ['listings'],

  setup() {
    const { state } = useApp()

    return {
      state,
    }
  },

  data: () => ({
    Price,
  }),

  methods: {
    getListingType(listing) {
      if (listing.market === Markets.dispenser) return 'Dispenser'
      if (listing.market === Markets.openSea) return 'OpenSea'
      return 'Sale'
    },

    getOriginalPrice(listing) {
      const price = Price.create(listing.price, listing.ticker)
      return price.pretty()
    },

    getConvertedPrice(listing) {
      const currencyPrefix = this.state.selectedCurrency.toLowerCase()
      const price = listing[`${currencyPrefix}Price`]
      const thePrice = Price.create(price, this.state.selectedCurrency)
      return thePrice.pretty()
    },
  },
}
</script>
