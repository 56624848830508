<template>
  <a
    v-if="floorListing"
    :href="floorListing.getUrl()"
    target="_blank"
  >
    <ShopIcon />
    Buy floor
  </a>

  <a
    :href="asset.getOpenSeaUrl()"
    target="_blank"
  >
    <EyeIcon />
    View on OpenSea</a>

  <a
    :href="asset.getXChainUrl()"
    target="_blank"
  >
    <EyeIcon />
    View on xChain
  </a>
</template>

<script>
import EyeIcon from './icons/EyeIcon'
import ShopIcon from './icons/ShopIcon'

export default {

  components: {
    EyeIcon,
    ShopIcon,
  },
  props: ['asset', 'floorListing'],
}
</script>
