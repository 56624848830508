<template>
  <div id="section_four" class="section">
    <Select2
      v-if="!!options.length"
      id="collections"
      ref="collectionSelector"
      :options="options"
      :model-value="selected"
      class="collections"
      name="state"
      style="min-width: 300px ;"
      @update:modelValue="onCollectionChange"
    >
      <option :value="selected">
        {{ selectedCollectionName }}
      </option>
    </Select2>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import Select2 from 'vue3-select2-component'

import { defaultCollection } from '../constants/appConstants'
import { getUriSelectedCollectionSlug } from '../services/UriService'
import { useRouter } from 'vue-router'
import { useApp } from '../composables/useApp'
import { useRepository } from '../composables/useRepository'

export default {
  components: {
    Select2,
  },
  setup() {
    const router = useRouter()
    const selected = getUriSelectedCollectionSlug(router)
    const { setAssets, setCollections, state, setSelectedCollectionSlug } = useApp(router)

    onMounted(async () => {
      await setAssets(useRepository())
      await setCollections(useRepository())
    })

    return {
      selected,
      router,
      state,
      setSelectedCollectionSlug
    }
  },

  data: () => ({
    debounce: null,
  }),

  computed: {
    options() {
      if (!this.state.collections.length) return [{ id: defaultCollection, text: 'Filter by project (All)' }]
      const sortedCollections = this.state.collections
        .map((collection) => ({ id: collection.slug, text: collection.name }))
        .sort((a, b) => a.text.localeCompare(b.text))
      return [{ id: defaultCollection, text: 'Filter by project (All)' }, ...sortedCollections]
    },

    selectedCollectionName() {
      const selectedOption = this.options.find((collection) => collection.id == this.selected)
      if (!selectedOption) return ''
      return selectedOption.text
    },
  },

  methods: {
    async onCollectionChange(id) {
        this.setSelectedCollectionSlug(id)
    },
  },
}
</script>

<style>
@import '../../public/css/select2.min.css';
</style>
