import { reactive } from 'vue'
import WebClient from '../services/WebClient'

const state = reactive({
  client: WebClient.init(),
})

export const useRepository = () => {
  const getLatestAnnouncement = async () => {
    return state.client.get({ route: state.client.routes.getLatestAnnouncement })
  }

  const getAggregates = async () => {
    return state.client.get({ route: state.client.routes.getAggregates })
  }

  const getAssetTokenIds = async () => {
    return state.client.get({ route: state.client.routes.getAssetTokenIds })
  }

  const getLatestSales = async () => {
    return state.client.get({ route: state.client.routes.getLatestsSales })
  }

  const getLatestListings = async () => {
    return state.client.get({ route: state.client.routes.getLatestListings })
  }

  const getAssets = async (options) => {
    return state.client.post({
      route: state.client.routes.getAssets,
      body: options,
    })
  }

  const getAsset = async (tokenId) => {
    const route = `${state.client.routes.getAsset}/${tokenId}`
    return state.client.get({ route })
  }

  const getAssetSales = async (tokenId) => {
    const route = `${state.client.routes.getSales}/${tokenId}`
    return state.client.get({ route })
  }

  const getAssetListings = async (tokenId) => {
    const route = `${state.client.routes.getListings}/${tokenId}`
    return state.client.get({ route })
  }

  const getCollections = async () => {
    return state.client.get({ route: state.client.routes.getCollections })
  }

  const getContributors = async () => {
    return state.client.get({ route: state.client.routes.getContributors })
  }

  const getLatestGdpr = async () => {
    return state.client.get({ route: state.client.routes.getGdpr })
  }

  const getAssetHolders = async (tokenId) => {
    const route = `${state.client.routes.getAsset}/${tokenId}/holders`
    return state.client.get({ route })
  }

  const getHoldersLeaderboard = async (page, ranking) => {
    return state.client.get({ route: `${state.client.routes.getHoldersLeaderboard}?ranking=${ranking}&page=${page}` })
  }

  const getHolder = async (address) => {
    const route = `${state.client.routes.getHolders}/${address}`
    return state.client.get({ route })
  }

  const getLatestFeatures = async () => {
    return state.client.get({ route: state.client.routes.getNextFeatures })
  }

  return {
    getLatestAnnouncement,
    getAggregates,
    getAssetTokenIds,
    getLatestSales,
    getLatestListings,
    getAssets,
    getAsset,
    getAssetSales,
    getAssetListings,
    getCollections,
    getContributors,
    getLatestGdpr,
    getAssetHolders,
    getHoldersLeaderboard,
    getHolder,
    getLatestFeatures,
  }
}
