<template>
  <div class="latest-listings">
    <h3 class="title-listings">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="currentColor"
        class="bi bi-shop"
        viewBox="0 0 16 16"
      >
        <path
          d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"
        />
      </svg>
      Latest listings
    </h3>
    <div class="latest-listings-container">
      <div class="listings-table">
        <div class="listings-table-row">
          <div class="listings-table-column-header-asset">
            <span>Asset</span>
          </div>
          <div class="listings-table-column-header">
            <span>Price</span>
          </div>
          <div class="listings-table-column-header">
            <span>Market</span>
          </div>
          <div class="listings-table-column-header listing-date">
            <span>Exp./Date</span>
          </div>
        </div>
      </div>
      <LatestListingsTableRow v-for="(listing, i) in listings" :key="`latest-listing-${i}`" :listing="listing" />
    </div>
  </div>
</template>

<script setup>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useApp } from '../composables/useApp'
import { useRepository } from '../composables/useRepository'
import LatestListingsTableRow from './LatestListingsTableRow.vue'
import Listing from '../models/Listing'

const repository = useRepository()

const router = useRouter()
const { state } = useApp(router)

const listingDTOs = ref([])

const listings = computed(() => {
  if (state.loadingRates) return []
  return listingDTOs.value.map((dto) => new Listing(dto, state.rates))
})

onMounted(async () => {
  try {
    listingDTOs.value = await repository.getLatestListings()
  } catch (error) {
    console.error(error)
  }
})

defineComponent({
  components: {
    LatestListingsTableRow,
  },
})
</script>

<style>
.latest_listings {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  min-width: 0;
  color: var(--white);
  background-color: var(--primary-color);
  border: 1px solid var(--grey-one);
  border-radius: var(--border-radius);
  margin: 0.5rem;
}

.title-listings {
  padding: 0.7rem 1rem;
  color: var(--white);
  font-size: 1rem;
}

.latest-listings-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5rem;
}

.listings-table {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  overflow-x: hidden;
  -webkit-border-top-left-radius: var(--border-radius);
  -webkit-border-top-right-radius: var(--border-radius);
  -moz-border-radius-topleft: var(--border-radius);
  -moz-border-radius-topright: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.listings-table-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--primary-color);
}

.listings-table-row:hover {
  background-color: var(--grey-one);
}

.listings-table-column-header-asset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.6rem;
  text-align: center;
  width: calc(100% / 3 * 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  background-color: var(--grey-one);
  color: var(--white);
  text-transform: uppercase;
  font-weight: var(--font-bold);
  white-space: nowrap;
}

.listings-table-column-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.6rem;
  text-align: center;
  width: 10%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  background-color: var(--grey-one);
  color: var(--white);
  text-transform: uppercase;
  font-weight: var(--font-bold);
  white-space: nowrap;
}

.listings-table-header {
  display: flex;
  align-items: self-end;
}
</style>
