<template>
  <Pagination :page-count="totalPages" :current-page="getCurrentPage()" @changePage="onPageChange" />
</template>

<script setup>
import { computed } from 'vue'

import { useRouter } from 'vue-router'
import { useApp } from '../composables/useApp'

const assetsPerPage = 50

const router = useRouter()

const { state, setCurrentPage, getPageFromUri } = useApp(router)

const totalPages = computed(() => {
  return Math.ceil(state.assetNumber / assetsPerPage)
})

const onPageChange = (page) => {
  if (page <= 0) page = 1
  if (page >= totalPages.value) page = totalPages.value
  setCurrentPage(page)
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const getCurrentPage = () => {
  const page = getPageFromUri()
  if (state.currentPage != page) setCurrentPage(page)
  return state.currentPage
}
</script>

<script>
import { defineComponent } from 'vue'

import Pagination from '../components/Pagination.vue'

export default defineComponent({
  components: {
    Pagination,
  },
})
</script>
