<template>
  <router-link v-if="listing" :to="`/detail/${listing.tokenId}`">
    <div class="listing-table-row">
      <div class="listing-table-column-asset listing-image-name">
        <img class="listing-picture" :src="`${apiUrl}/images/${getPictureFromPictures(listing.pictures)}`" />
        <div class="name-collection">
          <div class="listing-name">
            {{ listing.tokenId }}
          </div>
          <div class="collection">
            {{ listing.name }}
          </div>
        </div>
      </div>
      <div class="listing-table-column">
        <span class="price-listing">{{ Price.create(listing.price, listing.ticker).pretty() }}</span>
        <span class="price-currency-listing">{{ getConvertedPrice() }}</span>
      </div>
      <div class="listing-table-column">
        <CounterpartyIcon v-if="isMarketXcp(listing.market)" class="market-picture" />
        <OpenseaIcon v-else-if="isMarketOpensea(listing.market)" class="market-picture" />
        <img v-else class="market-picture" :src="`${apiUrl}/images/PLACEHOLDER.png`" />
      </div>
      <div class="listing-table-column listing-table-date">
        <span v-if="listing.expiration" class="expiration-listing">{{ formatDate(listing.expiration) }}</span>
        <span>{{ formatDate(listing.date) }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { useRouter } from 'vue-router'
import moment from 'moment'

import { getBaseApiUrl } from '../services/AppUrls'
import { TickerToSymbol } from '../enums/Tickers'
import { useApp } from '../composables/useApp'
import CounterpartyIcon from './icons/CounterpartyIcon.vue'
import OpenseaIcon from './icons/OpenseaIcon.vue'
import Price from '../models/Price'

export default {
  name: 'LatestListingsTableRow',

  components: {
    CounterpartyIcon,
    OpenseaIcon,
  },
  props: ['listing'],

  setup() {
    const router = useRouter()
    const { state } = useApp(router)

    return {
      state,
      apiUrl: getBaseApiUrl(),
    }
  },

  data: () => ({
    TickerToSymbol,
    Price,
  }),

  methods: {
    formatDate(date) {
      return moment(date).format('L')
    },

    getPictureFromPictures(pictures) {
      return pictures.split(';').shift()
    },

    isMarketXcp(market) {
      if (market == 'xcp') {
        return true
      }
      return false
    },

    isMarketOpensea(market) {
      if (market == 'openSea') {
        return true
      }
      return false
    },

    getConvertedPrice() {
      const currencyPrefix = this.state.selectedCurrency.toLowerCase()
      const price = this.listing[`${currencyPrefix}Price`]
      const thePrice = Price.create(price, this.state.selectedCurrency)
      return thePrice.pretty()
    },
  },
}
</script>

<style>
.name-collection {
  text-align: left;
}

.listing-name {
  font-weight: 400;
}

.collection {
  font-size: 12px;
  color: #9b9b9b;
}

.listing-table-column-asset {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.6rem;
  text-align: center;
  width: calc(100% / 3 * 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.listing-table-row {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--primary-color);
}

.listing-table-row:hover {
  background-color: var(--grey-one);
}

.listing-table-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.6rem;
  text-align: center;
  width: 10%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.listing-table-date {
  font-size: 12px;
  color: #9b9b9b;
}

.listing-image-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--font-bold);
}

.listing-picture {
  display: block;
  width: 40px;
  min-height: 1px;
  margin-right: 1rem;
}

.market-picture {
  display: block;

  width: 20px;
  min-height: 1px;
  margin: auto;
}

.price-listing {
  padding: 0.2rem;
  border-radius: var(--border-radius);
  background-color: var(--grey-one);
  color: var(--white);
  margin-bottom: 0.2rem;
}

.price-currency-listing {
  font-size: 0.9rem;
}

.expiration-listing {
  padding: 0.2rem;
  border-radius: var(--border-radius);
  background-color: var(--grey-one);
  color: var(--white);
  margin-bottom: 0.2rem;
}

@media screen and (max-width: 1000px) {
  .collection {
    font-size: 0.8rem;
  }

  .listing-table-date {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 450px) {
  .collection {
    font-size: 0.7rem;
  }

  .listing-table-date {
    font-size: 0.6rem;
  }
}
</style>
