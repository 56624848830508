<template>
  <router-link v-if="theAsset" :to="{ name: 'Asset Detail', params: { tokenId: asset.tokenId } }">
    <div class="tableRow">
      <div class="tableColumn image">
        <img v-if="!!assetPicture" :src="assetPicture" :alt="asset.tokenId" />
        <img v-else :src="`${apiUrl}/images/PLACEHOLDER.png`" />
        <div class="asset-name-cell">
          <div>{{ asset.tokenId }}</div>
          <div class="secondary-text mt-4">
            {{ theAsset.getCreationDate() }}
          </div>
        </div>
      </div>
      <div class="tableColumn">
        <span class="price">{{ theAsset.getPrettyFloorPrice() }}</span>
        <span>({{ theAsset.getPrettyFloorPriceInCurrency(state.selectedCurrency) }})</span>
      </div>
      <div class="tableColumn">
        <span class="price">{{ theAsset.getPrettyLastSalePrice() }}</span>
        <span>({{ theAsset.getPrettyPriceLastSalePriceInCurrency(state.selectedCurrency) }})</span>
      </div>
      <div class="tableColumn">
        <span>{{ theAsset.getSupply() }}</span>
      </div>
      <div class="tableColumn">
        <span>{{ theAsset.getPrettyMarketCap(state.selectedCurrency) }}</span>
      </div>
      <div class="tableColumn">
        <span>{{ theAsset.getTop3Holders() }}%</span>
      </div>
      <div class="tableColumn">
        <span>{{ theAsset.getForSalePercentage() }}%</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { useRouter } from 'vue-router'

import { getBaseApiUrl } from '../services/AppUrls'
import { useApp } from '../composables/useApp'
import Asset from '../models/Asset'

export default {
  props: ['asset'],

  setup() {
    const router = useRouter()
    const { state } = useApp(router)

    return {
      state,
      router,
      apiUrl: getBaseApiUrl(),
    }
  },

  data: () => ({
    theAsset: null,
  }),

  computed: {
    assetPicture() {
      const slug = this.state.collections && this.state.collections.slug
      return this.theAsset.getPicture(slug)
    },
  },

  created() {
    this.theAsset = new Asset(this.asset, this.state.rates)
  },
}
</script>

<style scoped>
.secondary-text{
  color: #b6b6b6;
}
</style>