<template>
  <div class="contributors-container">
    <h1>Our contributors ❤️</h1>
    <p>Thank you for your help!</p>

    <div class="contributor-cards">
      <ContributorCard v-for="(contributor, i) in contributors" :key="`contributor-${i}`" :contributor="contributor" />
    </div>
  </div>
</template>

<script>
import ContributorCard from './ContributorCard.vue'
import { useRepository } from '../composables/useRepository'

export default {
  components: {
    ContributorCard,
  },

  data: () => ({
    contributors: [],
  }),

  async created() {
    try {
      const repository = useRepository()
      this.contributors = await repository.getContributors()
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style>
.contributors-container {
  background-color: var(--pro);
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 40px;
  padding-bottom: 40px;
}

.contributor-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
  margin-top: 40px;
}

@media screen and (max-width: 1132px) {
  .contributors-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
</style>
