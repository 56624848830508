<template>
  <div id="chart">
    <highcharts v-if="salesChartData" constructor-type="stockChart" :options="salesChartData" />
  </div>
</template>

<script setup>
import { getDateStringFromDate } from '../services/dateHelpers'
import { Tickers, TickerToSymbol } from '../enums/Tickers'
import { usdToEur } from '../services/conversionHelpers'
import Price from '../models/Price'
import { useRouter } from 'vue-router'
import { useApp } from '../composables/useApp'
import { defineProps, ref, watch, onMounted } from 'vue'

const props = defineProps(['sales'])

const salesChartData = ref(null)

const { state } = useApp(useRouter())

watch(
  () => state.selectedCurrency,
  () => setChartData(),
)

const setChartData = async () => {
  const getPriceInCurrency = (price) => {
    if (state.selectedCurrency === Tickers.USD) return price
    const { EUR, USD } = state.rates[Tickers.BTC]
    return usdToEur(price, USD, EUR)
  }

  const salesData = [...props.sales].reverse().map((sale) => {
    const price = Price.create(sale.price, sale.ticker)

    return {
      x: new Date(sale.date).getTime(),
      y: Math.round(getPriceInCurrency(sale.usdPrice) / 100, 0),
      price: price.pretty(),
      date: getDateStringFromDate(new Date(sale.date)),
    }
  })

  const tickerSymbol = TickerToSymbol[state.selectedCurrency]

  const sales = props.sales
  const selectedCurrency = state.selectedCurrency

  salesChartData.value = {
    navigator: {
      series: {
        lineWidth: 0,
        fillOpacity: 0,
        barBackgroundColor: '#f0f0f0',
      },
      height: 30,
    },
    chart: {
      backgroundColor: null,
      height: 300,
      marginTop: 0,
      marginRight: 20,
    },
    xAxis: {
      ordinal: false,
      type: 'datetime',
      legend: { enabled: false },
      title: {
        enabled: false,
      },
      labels: {
        formatter: function () {
          var label = this.axis.defaultLabelFormatter.call(this)
          if (sales.length === 1) return getDateStringFromDate(new Date(sales[0].date))
          return label
        },
      },
    },
    yAxis: {
      title: {
        enabled: false,
      },
      labels: {
        align: 'left',
        reserveSpace: false,
        y: -4,
        x: 0,
        style: {
          color: 'rgba(255, 255, 255, 0.8)',
          fontSize: '8pt',
        },
        formatter: function () {
          var label = this.axis.defaultLabelFormatter.call(this)
          return `${label} ${tickerSymbol}`
        },
      },
    },
    tooltip: {
      formatter: function () {
        return this.points.reduce(function (s, point) {
          const dataPoint = point.point
          let content = ''
          content += `<span style='color:--grey-four;'><b>${dataPoint.date}</b></span> <br/>`
          content += `<b>${dataPoint.price}</b> (${new Price(dataPoint.y * 100, selectedCurrency).pretty()})`
          return content
        }, '<b>' + this.x + '')
      },
      shared: true,
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
        enableMouseTracking: true,
        turboThreshold: 100000,
      },
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      allButtonsEnabled: false,
      buttons: [
        {
          type: 'year',
          count: 1,
          text: '1y',
          title: 'View 1 year',
        },
      ],
      inputEnabled: false,
      selected: 0,
    },
    series: [
      {
        allowPointSelect: false,
        marker: {
          enabled: true,
        },
        showInLegend: false,
        borderWidth: 0.1,
        color: 'rgba(255, 255, 255, 0.8)',
        data: salesData,
      },
    ],
  }
}

onMounted(() => {
  setChartData()
})
</script>

<style>
rect.highcharts-navigator-mask-inside {
  fill: var(--grey-one);
}
.highcharts-range-selector-group {
  display: none;
}
</style>
