import BN from 'bignumber.js'
import { TickerToDisplayDecimals } from '../enums/Tickers'

/**
 * @param {number} usdPrice
 * @param {number} usdRate
 * @param {number} eurRate
 * @returns {string}
 */
export const usdToEur = (usdPrice, usdRate, eurRate) => {
  return new BN(usdPrice).multipliedBy(eurRate).dividedBy(usdRate).decimalPlaces(TickerToDisplayDecimals.EUR).toString()
}
