<template>
  <LoadingOverlay v-if="loading" />
  <div v-else class="holder-leaderboard-container">
    <div class="title">
      <h1 class="holder-title">
        Counterparty Holders Leaderboard
      </h1>
      <h2 class="holder-subtitle">
        Ranking by floor price valuation of Counterparty holders.
      </h2>
      <h2 class="holder-subtitle">
        The leaderboard only shows data for assets that are listed in the explorer.
      </h2>
      <h2 class="holder-subtitle">
        Assets with less than $1k sales volume over the year are ignored in the last sale valuation leaderboard.
      </h2>
    </div>
    <TabsMenu @onRankingChange="onRankingChange" />
    <div>
      <div class="holders-container">
        <LeaderboardCard v-for="(holder, i) in holders" :key="i" :holder="holder" :rank="getRank(i)" />
      </div>
      <LeaderboardPagination :current-page="currentPage" :total-pages="totalPages" @onPageChange="onPageChange" />
    </div>
  </div>
</template>

<script>
import LeaderboardCard from '../components/LeaderboardCard.vue'
import LeaderboardPagination from '../components/LeaderboardPagination.vue'
import LoadingOverlay from '../components/LoadingOverlay.vue'
import TabsMenu from '../components/TabsMenu.vue'
import { useRepository } from '../composables/useRepository'

const limit = 20

const rankingTypes = {
  'last-sale-valuation': 'last-sale-valuation',
  'floor-valuation': 'floor-valuation',
}

export default {
  components: {
    LeaderboardCard,
    LeaderboardPagination,
    LoadingOverlay,
    TabsMenu,
  },

  data: () => ({
    holders: [],
    loading: true,
    totalPages: 0,
  }),

  computed: {
    currentPage() {
      return Number(this.$route.query.page) || 1
    },

    currentRanking() {
      return rankingTypes[this.$route.query.ranking] || rankingTypes['last-sale-valuation']
    },
  },

  async created() {
    try {
      await this.setHoldersLeaderboard()
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  },

  methods: {
    onPageChange(newPage) {
      this.$router.push(`/leaderboard?ranking=${this.currentRanking}&page=${newPage}`)
    },

    onRankingChange(newRanking) {
      this.$router.push(`/leaderboard?ranking=${newRanking}&page=1`)
    },

    async setHoldersLeaderboard() {
      const repository = useRepository()
      const { data, count } = await repository.getHoldersLeaderboard(this.currentPage, this.currentRanking)
      this.holders = data
      this.totalPages = Math.ceil(count / limit)
    },

    getRank(i) {
      return (this.currentPage - 1) * limit + i + 1
    },

    getButtonClass() {
      // Review if more logic is added, should use a selected class
      return this.tab ? 'selectedTab' : 'otherTab'
    },
  },
}
</script>

<style>
.holder-leaderboard-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.leaderboard-tab-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
}

.title {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-top: 1rem;
}

.holder-title {
  font-size: 2rem;
  padding-bottom: 10px;
}

.holder-subtitle {
  font-size: 1rem;
  padding-bottom: 10px;
  font-weight: 500;
}

.holders-container {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .holder-leaderboard-container {
    order: 5;
    justify-content: start;
  }
}
</style>
