import AssetTableFields from './AssetTableFields'
import SortDirections from './SortDirections'

export const UriParams = {
  page: 'page',
  sortBy: 'sortBy',
  sortDirection: 'sortDirection',
}

export const DefaultUriParamsValues = {
  [UriParams.page]: 1,
  [UriParams.sortBy]: 'n',
  [UriParams.sortDirection]: 'a',
}

export const UriParamToMinimizedParam = {
  [UriParams.page]: 'pa',
  [UriParams.sortBy]: 'sb',
  [UriParams.sortDirection]: 'sd',
}

export const MinimizedParamToUriParam = Object.entries(UriParamToMinimizedParam).reduce(
  (acc, [uriParam, minimizedParam]) => ({ ...acc, [minimizedParam]: uriParam }),
  {},
)

export const UriSortFields = {
  n: AssetTableFields.name,
  f: AssetTableFields.floor,
  ls: AssetTableFields.lastSale,
  s: AssetTableFields.supply,
  mc: AssetTableFields.marketCap,
  t3: AssetTableFields.top3Holders,
  fs: AssetTableFields.forSale,
  cd: AssetTableFields.creationDate,
}

export const UriSortFieldsToMinimizedParam = Object.entries(UriSortFields).reduce(
  (acc, [minimizedParam, param]) => ({ ...acc, [param]: minimizedParam }),
  {},
)

export const UriSortDirections = {
  d: SortDirections.desc,
  a: SortDirections.asc,
}

export const SortDirectionToMinimizedParam = Object.entries(UriSortDirections).reduce(
  (acc, [minimizedParam, param]) => ({ ...acc, [param]: minimizedParam }),
  {},
)
