import { Tickers } from '../enums/Tickers'
import { usdToEur } from '../services/conversionHelpers'
import Price from '../models/Price'

// This file is a quick fix, create a Sale model if more functions are needed here
export const getConvertedPrice = (sale, selectedCurrency, rates) => {
  const getPriceAsString = (price) => {
    const thePrice = Price.create(price, selectedCurrency)
    return thePrice.pretty()
  }

  if (!sale.usdPrice) return '-'
  if (selectedCurrency === Tickers.USD) return getPriceAsString(sale.usdPrice)
  const eurPrice = usdToEur(sale.usdPrice, rates[Tickers.BTC][Tickers.USD], rates[Tickers.BTC][Tickers.EUR])
  return getPriceAsString(eurPrice)
}

export default { getConvertedPrice }
