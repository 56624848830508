<template>
  <div
    id="section_six"
    class="section"
  >
    <div id="assetTable">
      <div class="tableRow">
        <div class="tableColumn header">
          <span
            class="tableHeader"
            @click="onHeaderClicked(AssetTableFields.name)"
          >Asset
            <SortIcon :type="getSortingIconType(AssetTableFields.name)" />
          </span>
          <span
            class="tableHeader creation-date-header-item"
            @click="onHeaderClicked(AssetTableFields.creationDate)"
          >Creation date
            <SortIcon :type="getSortingIconType(AssetTableFields.creationDate)" />
          </span>
        </div>
        <div
          class="tableColumn header"
          @click="onHeaderClicked(AssetTableFields.floor)"
        >
          <span class="tableHeader">Floor price
            <SortIcon :type="getSortingIconType(AssetTableFields.floor)" />
          </span>
        </div>
        <div
          class="tableColumn header"
          @click="onHeaderClicked(AssetTableFields.lastSale)"
        >
          <span class="tableHeader">Last sale
            <SortIcon :type="getSortingIconType(AssetTableFields.lastSale)" />
          </span>
        </div>
        <div
          class="tableColumn header"
          @click="onHeaderClicked(AssetTableFields.supply)"
        >
          <span class="tableHeader">Supply
            <SortIcon :type="getSortingIconType(AssetTableFields.supply)" />
          </span>
        </div>
        <div
          class="tableColumn header"
          @click="onHeaderClicked(AssetTableFields.marketCap)"
        >
          <span class="tableHeader">Market cap
            <SortIcon :type="getSortingIconType(AssetTableFields.marketCap)" />
          </span>
        </div>
        <div
          class="tableColumn header"
          @click="onHeaderClicked(AssetTableFields.top3Holders)"
        >
          <span class="tableHeader">Top 3 holders
            <SortIcon :type="getSortingIconType(AssetTableFields.top3Holders)" />
          </span>
        </div>
        <div
          class="tableColumn header"
          @click="onHeaderClicked(AssetTableFields.forSale)"
        >
          <span class="tableHeader">For sale
            <SortIcon :type="getSortingIconType(AssetTableFields.forSale)" />
          </span>
        </div>
      </div>
      <AssetTableRow
        v-for="(asset, i) in assets"
        :key="`ai${asset.id}${i}`"
        :asset="asset"
      />
    </div>
    <AssetTablePagination />
  </div>
</template>

<script>
import AssetTableFields from '../enums/AssetTableFields'
import SortIconTypes from '../enums/SortIconTypes'
import SortDirections from '../enums/SortDirections'

import AssetTablePagination from '../components/AssetTablePagination'
import AssetTableRow from '../components/AssetTableRow'
import SortIcon from '../components/icons/SortIcon'
import { useRouter } from 'vue-router'
import { useApp } from '../composables/useApp'

export default {
  components: {
    AssetTablePagination,
    AssetTableRow,
    SortIcon,
  },

  setup() {
    const router = useRouter()
    const { assets, state } = useApp(router)
    return { router, assets, state }
  },

  data: () => ({
    AssetTableFields,
  }),

  methods: {
    getSortingIconType(field) {
      if (field !== this.state.sortBy) return SortIconTypes.sortable
      if (this.state.sortDirection === SortDirections.desc) return SortIconTypes.down
      return SortIconTypes.up
    },

    onHeaderClicked(field) {
      const { toggleSortDirection, setSortDirection, setSortBy, setCurrentPageWithoutAssetsRefresh } = useApp(
        this.router,
      )
      if (this.state.currentPage > 1) setCurrentPageWithoutAssetsRefresh(1)
      if (field === this.state.sortBy) {
        toggleSortDirection()
      } else {
        setSortBy(field)
        setSortDirection(SortDirections.desc)
      }
    },
  },
}
</script>

<style>
.tableHeader {
  display: flex;
  align-items: self-end;
}

.creation-date-header-item {
  margin-left: 20px;
}
</style>
