<template>
  <div id="information">
    <h3>
      <InformationIcon />
      Information
    </h3>

    <SimpleBar
      data-simplebar-auto-hide="false"
      class="content"
    >
      <table>
        <tbody>
          <tr>
            <td>Creation date</td>
            <td style="width: 90px">
              {{ theAsset.getCreationDate() }}
            </td>
          </tr>
          <tr>
            <td>Market cap.</td>
            <td>{{ theAsset.getPrettyMarketCap(state.selectedCurrency) }}</td>
          </tr>
          <tr>
            <td>Divisible</td>
            <td>{{ theAsset.divisible ? 'yes' : 'no' }}</td>
          </tr>
          <tr>
            <td>Locked</td>
            <td>{{ theAsset.locked ? 'yes' : 'no' }}</td>
          </tr>
          <tr>
            <td>Initial Supply</td>
            <td>{{ theAsset.getInitialSupply() }}</td>
          </tr>
          <tr>
            <td>Destructions</td>
            <td>{{ theAsset.getDestructions() || '-' }}</td>
          </tr>
          <tr>
            <td>Supply</td>
            <td>{{ theAsset.getSupply() }}</td>
          </tr>
          <tr v-if="theAsset.divisible">
            <td>Supply - whole cards</td>
            <td>{{ getWholeAssetSupply() }}</td>
          </tr>
          <tr>
            <td>Holders</td>
            <td>{{ getHoldersCount() }}</td>
          </tr>
          <tr v-if="theAsset.divisible">
            <td>Holders - Whole cards</td>
            <td>{{ getWholeHoldersCount() }}</td>
          </tr>
          <tr>
            <td>Top 3 holders</td>
            <td>{{ theAsset.getTop3Holders() }}%</td>
          </tr>
        </tbody>
      </table>
    </SimpleBar>
  </div>
</template>

<script>
import { SimpleBar } from 'simplebar-vue3'

import Asset from '../models/Asset'

import InformationIcon from '../components/InformationIcon.vue'
import { useApp } from '../composables/useApp'
import { useRouter } from 'vue-router'

export default {

  components: {
    InformationIcon,
    SimpleBar,
  },
  props: ['asset', 'holders'],

  setup() {
    const router = useRouter()
    const { state } = useApp(router)

    return {
      state,
    }
  },

  computed: {
    theAsset() {
      return new Asset(this.asset, this.state.rates)
    },
  },

  methods: {
    getHoldersCount() {
      return this.holders.filter(({ quantity }) => quantity >= 0).length
    },

    getWholeHoldersCount() {
      return this.holders.filter(({ quantity }) => quantity >= 1).length
    },

    getWholeAssetSupply() {
      return this.holders.reduce((acc, { quantity }) => acc + Math.floor(quantity), 0)
    },
  },
}
</script>
