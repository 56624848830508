<template>
  <div id="search-bar-container" class="section">
    <div class="form">
      <input id="search" v-model="search" placeholder="Search..." autocomplete="off">
      <div v-if="search.length" id="search_close" @click="onClearSearch" />
    </div>
    <SimpleBar v-if="results.length" id="search_results" data-simplebar-auto-hide="false">
      <div v-for="(result, i) in results" :key="`sr${result}${i}`" @click="onSearchResultClicked(result)">
        {{ result }}
      </div>
    </SimpleBar>
  </div>
</template>

<script>
import { SimpleBar } from 'simplebar-vue3'
import { useApp } from '../composables/useApp'
import { useRouter } from 'vue-router'
import { useRepository } from '../composables/useRepository'

export default {
  components: {
    SimpleBar,
  },

  setup() {
    const router = useRouter()
    const { state } = useApp(router)

    return { router, state }
  },
  data: () => ({
    search: '',
    results: [],
  }),

  watch: {
    search: {
      async handler(val) {
        try {
          if (!val || val === '') {
            this.results = []
            return
          }

          const tokenIds = this.state.assetTokenIds.filter((assetTokenId) => {
            return assetTokenId.toLowerCase().includes(val.toLowerCase())
          })

          tokenIds.sort((a, b) => a.length - b.length)

          this.results = tokenIds

          if (val.length === 34) {
            await useRepository().getHolder(val)
            this.results.push(val)
          }
        } catch (e) {
          this.results = []
        }
      },
      deep: true,
    },
  },

  async created() {
    const { setAssetTokenIds } = useApp(this.router)
    await setAssetTokenIds(useRepository())
  },

  methods: {
    onSearchResultClicked(result) {
      this.onClearSearch()
      if (result.length === 34) {
        this.$router.push(`/address/${result}`)
      } else this.$router.push({ path: `/detail/${result}` })
    },

    onClearSearch() {
      this.search = ''
      this.results = []
    },
  },
}
</script>

<style lang="scss">
#search-bar-container {
  display: flex;
  background-color: var(--grey-one);
  border-radius: var(--border-radius);
  height: 30px;

  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 200px;
    max-width: 500px;

    input {
      display: block;
      padding-left: 10px;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      border: none;
      outline: none;
      background-color: var(--grey-one);
      color: var(--grey-one);
      &::placeholder {
        color: var(--font-color);
      }
    }
    #search_close {
      display: flex;

      padding-right: 10px;
      padding-left: 10px;
      cursor: pointer;
      &::after {
        content: '\2715';
        font-size: 1rem;
        color: #ffff;
      }
    }
  }
  #search {
    color: var(--font-color);
  }
  .simplebar-content-wrapper {
    border-radius: 4px;
    border: 0.5px solid var(--white);
    background-color: var(--grey-two);
  }
  #search_results {
    color: var(--font-color);
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 200px;
    max-height: 400px;

    z-index: 20;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--primary-color);
    margin-top: 35px;
    -webkit-border-bottom-right-radius: var(--border-radius);
    -webkit-border-bottom-left-radius: var(--border-radius);
    -moz-border-radius-bottomright: var(--border-radius);
    -moz-border-radius-bottomleft: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    .simplebar-content {
      border-radius: var(--border-radius);
      div {
        cursor: pointer;
        padding: 0.5rem;

        &:hover {
          background-color: var(--grey-one);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #search-bar-container {
    display: flex;
    order: 3;
    .form {
      max-width: 100%;
      input {
        max-width: 100%;
      }
    }
    #search_results {
      max-width: 100%;
      border-radius: 0;
    }
  }
}
</style>
