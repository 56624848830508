<template>
  <div class="next-feature-container">
    <h1>What's coming next?</h1>
    <p>
      Here is an overview of what is planned for the next future. Give us a boost by co-funding the development of
      HNFT.wtf!
    </p>
    <div class="features-cards">
      <NextFeatureCard v-for="(feature, i) in features" :key="`feature-${i}`" :feature="feature" />
    </div>
  </div>
</template>

<script>
import NextFeatureCard from '../components/NextFeatureCard.vue'
import { useRepository } from '../composables/useRepository'

export default {
  name: 'NextFeature',
  components: {
    NextFeatureCard,
  },

  data: () => ({
    features: [],
  }),

  async created() {
    try {
      const repository = useRepository()
      this.features = await repository.getLatestFeatures()
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style>
.next-feature-container {
  background-color: var(--grey-one);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 40px;
  padding-bottom: 80px;
}

.features-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
  margin-top: 20px;
}

@media screen and (max-width: 1132px) {
  .next-feature-container {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
</style>
