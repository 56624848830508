<template>
  <div class="privacy-page-container">
    <div class="privacy-page-title">
      HNFT Privacy Policy
    </div>
    <div class="privacy-text">
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem rerum provident est ipsa voluptas tenetur
      iusto iure magni, harum id dignissimos, voluptate, atque expedita voluptates distinctio quod similique debitis.
      Voluptas?
    </div>
    <div class="privacy-title">
      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
    </div>
    <div class="privacy-text">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique nulla blanditiis, recusandae ipsa placeat
      tempore doloremque reiciendis explicabo! Voluptatibus, ea corporis. Illum id, sunt assumenda ipsa cumque veritatis
      quam aliquam ad distinctio, laudantium similique quaerat tenetur ipsum, animi iste hic porro quae praesentium
      fugiat at fugit obcaecati? Suscipit dolor repudiandae accusantium, sed eos numquam illum consequatur velit?
      Mollitia neque, provident odio voluptatum a reiciendis, quisquam fugiat animi nisi, soluta nostrum quae excepturi
      expedita. Quam quod ad sunt illo earum quo iure doloribus velit, cupiditate vero. Eius, nemo officia pariatur
      excepturi aperiam debitis sunt architecto incidunt praesentium illo ratione animi blanditiis accusantium
      consequatur eligendi hic. Quidem error consequuntur sint adipisci amet ut fuga pariatur obcaecati dolorem animi
      nisi esse saepe deleniti culpa facere veniam sit, commodi enim quasi natus deserunt! Ab amet, praesentium impedit
      rerum, illum quis dicta fugit nam accusamus eveniet voluptatibus quisquam, accusantium beatae delectus culpa
      commodi incidunt dolor.
    </div>
    <div class="privacy-text">
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus veniam quas cum rem aspernatur aliquam id
      reprehenderit, maxime, ducimus atque quos, at inventore? Deleniti, vel tempore facilis, ipsum quia debitis laborum
      sunt dicta aperiam, doloremque sit voluptates! Magnam, aperiam iure. Harum odio omnis molestiae. Officiis ipsam
      quaerat veniam, reiciendis aperiam animi, cumque fuga laborum aliquam deserunt, aliquid est. Hic sit quas
      obcaecati non magni. Eligendi sapiente suscipit eum itaque officiis odio vitae corporis, fugiat natus qui fugit
      repellendus ex veniam sunt nobis quisquam distinctio, aliquam neque sed culpa pariatur cum. Repudiandae aliquid
      dolorum cum dolores voluptates illum numquam maxime at.
    </div>
    <div class="privacy-text">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur atque laborum deleniti necessitatibus minus
      molestiae laudantium, voluptates at? Reprehenderit quas vitae eligendi tempore exercitationem itaque.
    </div>
    <div class="privacy-title">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia tenetur magni id!
    </div>
    <div class="privacy-text">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sunt esse autem minus, facilis, doloribus nobis tempore
      quis in fugit at temporibus dolorum. Necessitatibus corrupti, reiciendis ipsam officiis temporibus illo delectus
      possimus et quisquam vel ducimus nobis laudantium sunt quo veritatis sapiente tempora sequi vitae placeat
      accusamus dolor commodi dolorem! Illo dolore fugit ratione iusto quos?
    </div>
    <div class="privacy-text">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut, quaerat? Iusto aliquam quo nihil praesentium expedita
      porro, mollitia itaque odit!
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
.privacy-page-container {
  background-color: white;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 40px;
  color: black;
}

.privacy-page-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
  margin-top: 40px;
  text-align: center;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
}

.privacy-title {
  font-size: 20px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
  color: black;
  text-transform: uppercase;
}

.privacy-text {
  font-size: 16px;
  line-height: 1.5;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--grey-three);
  text-align: justify;
}
</style>
