import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Explorer from '../views/Explorer.vue'
import AssetDetail from '../views/AssetDetail.vue'
import Leaderboard from '../views/Leaderboard.vue'
import AddressDetail from '../views/AddressDetail.vue'
import Privacy from '../views/Privacy.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/explorer/:collection?',
    name: 'Explorer',
    component: Explorer,
  },
  {
    path: '/detail/:tokenId',
    name: 'Asset Detail',
    component: AssetDetail,
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: Leaderboard,
    query: { ranking: 'last-sale-valuation', page: 1 },
  },
  {
    path: '/address/:address',
    name: 'Address Detail',
    component: AddressDetail,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  let documentTitle = `${process.env.VUE_APP_TITLE}`
  if (to.name) documentTitle += ` - ${to.name}`
  if (to.params.collection) documentTitle = `${process.env.VUE_APP_TITLE} - ${to.params.collection}`
  if (to.params.tokenId) documentTitle = `${process.env.VUE_APP_TITLE} - ${to.params.tokenId}`
  if (to.params.address) documentTitle = `${process.env.VUE_APP_TITLE} - ${to.params.address}`
  document.title = documentTitle
  next()
})

export default router
