<template>
  <div class="footer-wrapper">
    <div class="footer-top-bar">
      <div>
        <!-- <div class="footer-title">HNFT</div> -->
        <div class="footer-subtitle">
          HNFT.wtf gives information about sales and open offers of assets on XChain and OpenSea.
        </div>
      </div>
      <div class="footer-donate">
        <span id="donate-ether">Donate</span><span class="address">nft1dev.eth</span>
      </div>
    </div>
    <div class="copyright">
      © 2022 HNFT. All Rights Reserved. Created with ❤️ by the<a
        href="https://www.nft1.com/"
        target="_blank"
        class="nft1-link"
      >NFT1 Team.</a>
    </div>
    <div class="footer-contact">
      <div>
        <a class="footer-contact-social-link" href="https://twitter.com/nft1dev" target="_blank">
          <TwitterIcon />
        </a>
        <a class="footer-contact-social-link" href="https://discord.gg/8yEWAeScte" target="_blank">
          <DiscordIcon />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '../../public/images/HNFT_Logo_light_single.svg'
import DiscordIcon from './icons/DiscordIcon.vue'
import TwitterIcon from './icons/TwitterIcon.vue'


export default {

  components: {
    DiscordIcon,
    TwitterIcon,
  },

  data: () => ({
    logo,
  }),
}
</script>

<style>
.footer-wrapper {
  display: flex;
  flex-direction: column;
  order: 7;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--grey-one);
  margin-top: 2rem;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
}

.footer-top-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0rem;
}

.footer-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  width: 60%;
}

.footer-subtitle {
  font-size: 0.9rem;
  font-weight: 500;
}

.footer-donate {
  display: block;
}

#donate-ether {
  padding: 0.3rem;
  background-color: var(--grey-one);
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: 0.3s;
  margin-left: 1rem;
}

#donate-ether:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.address {
  padding: 0.3rem;
  background-color: var(--grey-four);
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: 0.3s;
}

.footer-second-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  height: 100px;
}

/* Should be removed if more than one section */
.only-one-section-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.footer-menu-section-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
  color: white;
}

.footer-menu-element {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
  color: white;
  transition: 0.3s;
  cursor: pointer;
}

.footer-menu-element:hover {
  color: var(--grey-four);
}

.footer-social-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-left: auto;
  height: 40px;
  padding-right: 0;
}

.footer-social {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  gap: 20px;
  padding-bottom: 20px;
}

.footer-social-link {
  display: grid;
  color: var(--white);
  place-items: center;
  transition: 0.2s ease-out;
}

.footer-social-link:hover {
  color: var(--grey-four);
}

.copyright {
  flex-basis: 100%;
  text-align: center;
  color: white;
  border-top: 1px solid var(--primary-color);
  padding-top: 20px;
}

.nft1-link {
  color: var(--white);
  cursor: pointer;
  transition: 0.2s ease-out;
  padding-left: 2px;
}

.nft1-link:hover {
  color: var(--grey-four);
}
.footer-contact {
  display: flex;
  justify-content: center;
  padding-top: 10px;

}

.footer-contact-label {
  font-size: 11px;
  width: 40%;
  text-align: center;
  color: #fff;
  padding-right: 5px;
}

.footer-contact-social-link {
  margin: 5px;
  color: #fff;
  transition: 0.2s;
}

.footer-contact-social-link:hover {
  color: #656565;
  cursor: pointer;
}
</style>
