import BN from '../services/BigNumber'
import { TickerToDecimals, TickerToDisplayDecimals, TickerToCent, TickerToSymbol } from '../enums/Tickers'

class Price {
  static create(priceInCents, ticker) {
    return new Price(priceInCents, ticker)
  }

  constructor(price, ticker) {
    this.price = new BN(price)
    this.ticker = ticker
  }

  pretty(options = {}) {
    const { noSymbol } = options

    const price = this.getPriceInUnitsAsString()
    const [int, dec] = String(price).split('.')

    if (Number(int) === 0 && Number(dec) === 0) {
      if (noSymbol) return this.price
      const symbol = TickerToCent[this.ticker] || 'cts'
      return `${this.price} ${symbol}`
    }

    const intAsString = new BN(int).toString()
    const displayedDecimalNumber = this.getDisplayedDecimalNumber(intAsString, this.ticker)
    if (!dec || displayedDecimalNumber === 0) {
      if (noSymbol) return intAsString
      return `${intAsString} ${TickerToSymbol[this.ticker]}`
    }

    const decimals = dec.length > displayedDecimalNumber ? dec.substring(0, displayedDecimalNumber) : dec
    const decimalsWithoutTrailingZeros = decimals.replace(/0+$/, '')
    const priceAsString = `${intAsString}.${decimalsWithoutTrailingZeros}`

    if (noSymbol) return priceAsString
    return `${priceAsString} ${TickerToSymbol[this.ticker]}`
  }

  getPriceInUnitsAsString() {
    const decimalsNumber = TickerToDecimals[this.ticker]
    return this.price.div(10 ** decimalsNumber).toFixed(decimalsNumber)
  }

  getDisplayedDecimalNumber(intAsString, ticker) {
    if (intAsString.length < 2) return TickerToDisplayDecimals[ticker] || 2
    if (intAsString.length < 4) return 2
    return 0
  }
}

export default Price
