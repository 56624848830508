<template>
  <div class="feature-card-container">
    <div v-if="feature.price" class="feature-tags">
      <FundableTag />
    </div>
    <div class="feature-information">
      <p class="feature-title">
        {{ feature.title }}
      </p>
      <p class="feature-description">
        {{ feature.description }}
      </p>
    </div>
    <div v-if="feature.price" class="contribution-overview-container">
      <ContributionOverview :feature="feature" />
    </div>
  </div>
</template>

<script>
import FundableTag from './tags/FundableTag.vue'
import ContributionOverview from './ContributionOverview.vue'

export default {
  components: {
    FundableTag,
    ContributionOverview,
  },

  props: ['feature'],
}
</script>

<style>
.feature-card-container {
  position: relative;
  background-color: var(--grey-two);
  width: 100%;
  padding-bottom: 120px;

  border-radius: var(--border-radius);
  transition: 0.3s;
}

.feature-card-container:hover {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  scale: 1.05;
}

.feature-information {
  padding: 20px;
}

.feature-title {
  font-size: 1.2rem;
  font-weight: var(--font-bold);
  margin-bottom: 15px;
  margin-top: 20px;
}

.feature-description {
  font-size: 0.8rem;
}

.feature-tags {
  position: absolute;
  top: 10px;
  right: 10px;
}

.contribution-overview-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
