<template>
  <div>
    <CollectionSelector />
    <AssetTable />
  </div>
</template>

<script>
import CollectionSelector from '../components/CollectionSelector.vue'
import AssetTable from '../components/AssetTable.vue'

export default {
  components: {
    CollectionSelector,
    AssetTable,
  },
}
</script>
