<template>
  <div id="last_sales">
    <h3>
      <CartIcon />
      Last sales
    </h3>
    <SimpleBar data-simplebar-auto-hide="false" class="content">
      <table>
        <thead>
          <tr class="header">
            <th>Date</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(sale, i) in sales" :key="`sale${i}`">
            <td>{{ getTimestampFromSqlTimestamp(sale.date) }}</td>
            <td>
              <span class="price">{{ getOriginalPrice(sale) }}</span>
              ({{ getConvertedPrice(sale) }})
            </td>
            <td>{{ sale.quantity }}</td>
            <td>
              <a class="link" :href="getSaleUrl(sale)" target="_blank">
                <LinkIcon />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </SimpleBar>
  </div>
</template>

<script>
import { getTimestampFromSqlTimestamp } from '../services/dateHelpers'
import Markets from '../enums/Markets.js'
import Price from '../models/Price'
import SalesService from '../services/SalesService'

import CartIcon from './icons/CartIcon.vue'
import LinkIcon from './icons/LinkIcon.vue'
import { SimpleBar } from 'simplebar-vue3'
import { useApp } from '../composables/useApp'

export default {
  components: {
    CartIcon,
    LinkIcon,
    SimpleBar,
  },
  props: ['sales'],

  setup() {
    const { state } = useApp()

    return {
      state,
    }
  },

  data: () => ({
    Price,
    getTimestampFromSqlTimestamp,
  }),

  methods: {
    getSaleUrl(sale) {
      if ([Markets.xcp, Markets.dispenser].includes(sale.market)) {
        return `https://tokenscan.io/tx/${sale.txUid}`
      }

      return `https://etherscan.io/tx/${sale.txHash}`
    },

    getOriginalPrice(sale) {
      const price = Price.create(sale.price, sale.ticker)
      return price.pretty()
    },

    getConvertedPrice(sale) {
      return SalesService.getConvertedPrice(sale, this.state.selectedCurrency, this.state.rates)
    },
  },
}
</script>
