<template>
  <router-link v-if="sale" :to="`/detail/${sale.tokenId}`">
    <div class="sales-table-row">
      <div class="sales-table-column-asset sales-image-name">
        <img class="sales-picture" :src="`${apiUrl}/images/${getPictureFromPictures(sale.pictures)}`" />
        <div class="name-collection">
          <div class="sales-name">
            {{ sale.tokenId }}
          </div>
          <div class="collection">
            {{ sale.name }}
          </div>
        </div>
      </div>
      <div class="sales-table-column">
        <span class="price-sales">{{ Price.create(sale.price, sale.ticker).pretty() }}</span>
        <span class="price-currency-sales">{{ getConvertedPrice() }}</span>
      </div>
      <div class="sales-table-column">
        <CounterpartyIcon v-if="isMarketXcp(sale.market)" class="market-picture" />
        <OpenseaIcon v-else-if="isMarketOpensea(sale.market)" class="market-picture" />
        <img v-else class="market-picture" :src="`${apiUrl}/images/PLACEHOLDER.png`" />
      </div>
      <div class="sales-table-column sales-table-date">
        <span>{{ formatDate(sale.date) }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { useRouter } from 'vue-router'
import moment from 'moment'

import { getBaseApiUrl } from '../services/AppUrls'
import { TickerToSymbol } from '../enums/Tickers'
import { useApp } from '../composables/useApp'
import CounterpartyIcon from './icons/CounterpartyIcon.vue'
import OpenseaIcon from './icons/OpenseaIcon.vue'
import Price from '../models/Price'
import SalesService from '../services/SalesService'

export default {
  name: 'LatestSalesTableRow',

  components: {
    CounterpartyIcon,
    OpenseaIcon,
  },

  props: ['sale'],

  setup() {
    const router = useRouter()
    const { state } = useApp(router)

    return {
      state,
      apiUrl: getBaseApiUrl(),
    }
  },

  data: () => ({
    TickerToSymbol,
    Price,
  }),

  methods: {
    formatDate(date) {
      return moment(date).format('L')
    },

    getPictureFromPictures(pictures) {
      return pictures.split(';').shift()
    },

    isMarketXcp(market) {
      if (market == 'xcp') {
        return true
      }
      return false
    },

    isMarketOpensea(market) {
      if (market == 'openSea') {
        return true
      }
      return false
    },

    getConvertedPrice() {
      return SalesService.getConvertedPrice(this.sale, this.state.selectedCurrency, this.state.rates)
    },
  },
}
</script>

<style>
.name-collection {
  text-align: left;
}

.sales-name {
  font-weight: 400;
}

.collection {
  font-size: 12px;
  color: #9b9b9b;
}

.sales-table-column-asset {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.6rem;
  text-align: center;
  width: calc(100% / 3 * 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sales-table-row {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--primary-color);
}

.sales-table-row:hover {
  background-color: var(--grey-one);
}

.sales-table-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.6rem;
  text-align: center;
  width: 10%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sales-image-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--font-bold);
}

.sales-picture {
  display: block;
  width: 40px;
  min-height: 1px;
  margin-right: 1rem;
}

.market-picture {
  display: block;

  width: 20px;
  min-height: 1px;
  margin: auto;
}

.price-sales {
  padding: 0.2rem;
  border-radius: var(--border-radius);
  background-color: var(--grey-one);
  color: var(--white);
  margin-bottom: 0.2rem;
}

.price-currency-sales {
  font-size: 0.9rem;
}

.sales-table-date {
  font-size: 12px;
  color: #9b9b9b;
}

@media screen and (max-width: 1000px) {
  .collection {
    font-size: 0.8rem;
  }

  .sales-table-date {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 450px) {
  .collection {
    font-size: 0.7rem;
  }

  .sales-table-date {
    font-size: 0.6rem;
  }
}
</style>
