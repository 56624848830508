<template>
  <div>
    <div class="latest-sales-listings">
      <LatestSalesTable class="half-width" />
      <LatestListingsTable class="half-width" />
    </div>
    <NextFeature />
    <Contributors />
  </div>
</template>

<script>
import LatestSalesTable from '../components/LatestSalesTable.vue'
import LatestListingsTable from '../components/LatestListingsTable.vue'

import NextFeature from '../components/NextFeature.vue'
import Contributors from '../components/Contributors.vue'

export default {
  components: {
    LatestSalesTable, //
    LatestListingsTable,
    NextFeature,
    Contributors,
  },
}
</script>

<style>
.latest-sales-listings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.half-width {
  flex: 1;
}

@media screen and (max-width: 1132px) {
  .latest-sales-listings {
    flex-direction: column;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .latest-sales-listings {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 450px) {
  .latest-sales-listings {
    font-size: 0.7rem;
  }
}
</style>
