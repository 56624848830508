<template>
  <div v-if="showBanner">
    <div class="announcement-banner">
      <div class="announcement-wrapper">
        <div class="announcement-text">
          <div v-if="isThereATitle()" class="banner-title">
            <p>{{ announcement.title }}</p>
          </div>
          <div v-if="isClickable()">
            <a :href="getLink">
              <!-- eslint-disable vue/no-v-html -->
              <p v-html="sanitizedDescription" />
              <!--eslint-enable-->
            </a>
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <p v-else v-html="sanitizedDescription" />
          <!--eslint-enable-->
        </div>
        <div class="announcement-close-button" @click="closeBanner">
          <CloseIcon style="height: 24px; width: 24px; fill: white" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify'

import CloseIcon from './icons/CloseIcon.vue'
import { useRepository } from '../composables/useRepository'

export default {
  components: { CloseIcon },

  data: () => ({
    showBanner: false,
    announcement: null,
    sanitizedDescription: null,
  }),

  async created() {
    try {
      const lastCookieDate = new Date(this.$cookies.get('announcement-banner-closed'))
      const repository = useRepository()
      this.announcement = await repository.getLatestAnnouncement()
      if (this.announcement && this.isDateGreaterThan(new Date(this.announcement.updatedAt), lastCookieDate)) {
        this.showBanner = true
        this.sanitizedDescription = DOMPurify.sanitize(this.announcement.description)
      }
    } catch (error) {
      console.error(error)
    }
  },

  methods: {
    closeBanner() {
      this.showBanner = false
      this.$cookies.set('announcement-banner-closed', this.announcement.updatedAt, '7d')
    },

    isDateGreaterThan(date1, date2) {
      return date1.getTime() > date2.getTime()
    },

    isClickable() {
      if (!this.announcement.link) return false
      return true
    },

    isThereATitle() {
      if (!this.announcement.title) return false
      return true
    },

    getLink() {
      return this.announcement.link
    },
  },
}
</script>

<style>
.announcement-banner {
  background-color: #900016;
  color: #ffffff;
  padding: 10px;
}

.announcement-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
}

.banner-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.announcement-text {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8rem;
  width: 100%;
  white-space: pre-line;
}

.announcement-close-button {
  right: 10px;
  cursor: pointer;
}

.announcement-close-button:hover {
  opacity: 0.8;
}
</style>
