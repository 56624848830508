import BN from 'bignumber.js'

import { getBaseApiUrl } from '../services/AppUrls'
import { getTimestampFromSqlTimestamp } from '../services/dateHelpers'
import { Tickers, TickerToDecimals } from '../enums/Tickers'
import { usdToEur } from '../services/conversionHelpers'
import Price from './Price'

class Asset {
  constructor(
    {
      id,
      tokenId,
      assetId,
      name,
      description,
      creationDate,
      comments,
      divisible,
      locked,
      supply,
      destructions,
      burns,
      holders,
      collection_id,
      collection_names,
      pictures,
      forSalePercentage,
      top3HoldersPercentage,
      marketCap,
      floorPrice,
      floorTicker,
      floorUsdPrice,
      floorTxUid,
      lastSaleDate,
      lastSaleTxUid,
      lastSalePrice,
      lastSaleTicker,
      lastSaleUsdPrice,
      lastSaleEthPrice,
      lastSaleBtcPrice,
      lastSaleXcpPrice,
      updatedAt,
    },
    rates,
  ) {
    this.id = id
    this.tokenId = tokenId
    this.assetId = assetId
    this.name = name
    this.description = description
    this.creationDate = creationDate
    this.comments = comments
    this.divisible = divisible
    this.locked = locked
    this.supply = supply
    this.destructions = destructions
    this.burns = burns
    this.holders = holders
    this.collection_id = collection_id
    this.collection_names = collection_names
    this.pictures = pictures
    this.forSalePercentage = forSalePercentage
    this.top3HoldersPercentage = top3HoldersPercentage
    this.marketCap = marketCap
    this.floorPrice = floorPrice
    this.floorTicker = floorTicker
    this.floorUsdPrice = floorUsdPrice
    this.floorTxUid = floorTxUid
    this.lastSaleDate = lastSaleDate
    this.lastSaleTxUid = lastSaleTxUid
    this.lastSalePrice = lastSalePrice
    this.lastSaleTicker = lastSaleTicker
    this.lastSaleUsdPrice = lastSaleUsdPrice
    this.lastSaleEthPrice = lastSaleEthPrice
    this.lastSaleBtcPrice = lastSaleBtcPrice
    this.lastSaleXcpPrice = lastSaleXcpPrice
    this.updatedAt = updatedAt

    this.rates = rates
    this.collections = collection_names ? collection_names.split(';') : ''
    this.setConvertedPrices()
  }

  setConvertedPrices() {
    const usdRate = this.rates[Tickers.BTC][Tickers.USD]
    const eurRate = this.rates[Tickers.BTC][Tickers.EUR]
    this.floorEurPrice = usdToEur(this.floorUsdPrice, usdRate, eurRate)
    this.eurMarketCap = usdToEur(this.marketCap, usdRate, eurRate)
  }

  getPrettyLastSalePrice() {
    return this.getPrettyPrice(this.lastSalePrice, this.lastSaleTicker)
  }

  getPrettyFloorPrice() {
    return this.getPrettyPrice(this.floorPrice, this.floorTicker)
  }

  getPrettyPrice(priceInCents, ticker) {
    if (!priceInCents || new BN(priceInCents).eq(0)) return '-'
    const price = Price.create(priceInCents, ticker)
    return price.pretty()
  }

  getPrettyPriceLastSalePriceInCurrency(ticker) {
    return this.getPrettyPriceInCurrency(this.lastSaleUsdPrice, ticker)
  }

  getPrettyFloorPriceInCurrency(ticker) {
    const priceKey = ticker === Tickers.EUR ? 'floorEurPrice' : 'floorUsdPrice'
    return this.getPrettyPriceInCurrency(this[priceKey], ticker)
  }

  getPrettyPriceInCurrency(priceInCurrency, ticker) {
    if (!priceInCurrency || priceInCurrency === '0') return '-'
    const price = Price.create(priceInCurrency, ticker)
    return price.pretty()
  }

  getPicture(selectedCollectionName) {
    if (!this.pictures) return null
    const projectIndex = this.collections.indexOf(selectedCollectionName)
    const pictureIndex = projectIndex === -1 ? 0 : projectIndex
    const pictureName = this.pictures.split(';')[pictureIndex]
    return `${getBaseApiUrl()}/images/${pictureName}`
  }

  getForSalePercentage() {
    return Math.round(this.forSalePercentage / 100)
  }

  getPrettyMarketCap(ticker) {
    const marketCapInCurrency = ticker === Tickers.USD ? this.marketCap : this.eurMarketCap
    const marketCapInUnits = new BN(marketCapInCurrency).div(10 ** TickerToDecimals[ticker])

    if (marketCapInUnits.gt(1_000_000_000)) {
      return `${marketCapInUnits.div(1_000_000).decimalPlaces(0).toFormat()}M`
    }

    if (marketCapInUnits.gt(1_000_000)) {
      return `${marketCapInUnits.div(1_000_000).decimalPlaces(1).toFormat()}M`
    }

    if (marketCapInUnits.gt(1_000)) {
      return `${marketCapInUnits.div(1_000).decimalPlaces(0).toFormat()}K`
    }

    return `${marketCapInUnits.toString()}`
  }

  getTop3Holders() {
    return new BN(this.top3HoldersPercentage).div(100).decimalPlaces(2).toString()
  }

  getInitialSupply() {
    return new BN(this.supply).plus(this.destructions || 0).toFormat()
  }

  getSupply() {
    return new BN(this.supply).minus(this.burns).toFormat()
  }

  getDestructions() {
    return new BN(this.destructions || 0).plus(this.burns).toFormat()
  }

  getXChainUrl() {
    return `https://tokenscan.io/asset/${this.tokenId}`
  }

  getOpenSeaUrl() {
    let url = `https://opensea.io/assets?search[collections][0]=emblem-vault&search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0]`
    url += `[name]=${this.tokenId}`
    url += '%3ABalance&search[stringTraits][0][values][0]=1&search[toggles][0]=IS_LISTED'
    return url
  }

  /**
   * @returns {string}
   */
  getCreationDate() {
    return getTimestampFromSqlTimestamp(this.creationDate)
  }
}

export default Asset
