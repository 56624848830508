<template>
  <div>
    <div class="contribution-data">
      <div class="contribution-half-width">
        <p class="contribution-value">
          {{ feature.contributionNbr }}
        </p>
        <p class="contribution-label">
          contributions
        </p>
      </div>
      <div class="contribution-half-width">
        <p class="contribution-value">
          ${{ feature.funded }}
        </p>
        <p class="contribution-label">
          Out of ${{ feature.price }}
        </p>
      </div>
    </div>
    <div class="progess-bar-container">
      <KProgress
        :percent="getPercent"
        :color="getColor"
        :bg-color="getBackgroundColor"
        :line-height="8"
        class="progress-bar-text"
      />
    </div>
    <div class="contribution-contact">
      <p class="contribution-contact-label">
        Contact us if you want to contribute
      </p>
      <div>
        <a class="contribution-contact-social-link" href="https://twitter.com/nft1dev" target="_blank">
          <TwitterIcon />
        </a>
        <a class="contribution-contact-social-link" href="https://discord.gg/8yEWAeScte" target="_blank">
          <DiscordIcon />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import KProgress from 'k-progress-v3'
import DiscordIcon from './icons/DiscordIcon.vue'
import TwitterIcon from './icons/TwitterIcon.vue'

export default {
  name: 'ContributionOverview',

  components: {
    KProgress,
    DiscordIcon,
    TwitterIcon,
  },

  props: ['feature'],

  data: () => ({
    percent: 0,
  }),

  computed: {
    getPercent() {
      return Math.round((this.feature.funded / this.feature.price) * 100)
    },

    getColor() {
      if (this.percent < 40) {
        return '#ffc069'
      } else if (this.percent < 60) {
        return '#fadb14'
      } else if (this.percent < 80) {
        return '#13c2c2'
      } else {
        return '#389e0d'
      }
    },

    getBackgroundColor() {
      return '#b6b6b6'
    },
  },

  mounted() {
    this.percent = this.getPercent
  },
}
</script>

<style>
.contribution-data {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
}

.contribution-half-width {
  flex: 1;
  text-align: center;
}

.contribution-value {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.contribution-label {
  font-size: 12px;
  color: #fff;
}

.progess-bar-container {
  padding-left: 25px;
}

.progress-bar-text {
  color: #fff;
}

.contribution-contact {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 20px;
}

.contribution-contact-label {
  font-size: 11px;
  width: 40%;
  text-align: center;
  color: #fff;
  padding-right: 5px;
}

.contribution-contact-social-link {
  margin-left: 15px;
  color: #fff;
  transition: 0.2s;
}

.contribution-contact-social-link:hover {
  color: #656565;
  cursor: pointer;
}
</style>
