import { ref, reactive } from 'vue'
import {
  getUriPage,
  getUriSelectedCollectionSlug,
  getUriSortBy,
  getUriSortDirection,
  setUriSortBy,
  setUriSortDirection,
  setUriSelectedCollectionSlug,
  setUriPage,
} from '../services/UriService'
import { Tickers, TickerToSymbol } from '../enums/Tickers'
import AssetTableFields from '../enums/AssetTableFields'
import Price from '../models/Price'
import SortDirections from '../enums/SortDirections'

const state = reactive({
  assetNumber: 0,
  collections: [],
  selectedCollectionSlug: null,
  currentPage: 1,
  sortBy: AssetTableFields.name,
  sortDirection: SortDirections.asc,
  rates: {},
  loadingRates: true,
  selectedCurrency: Tickers.USD,
  assetTokenIds: [],
})

const assets = ref([])

export const useApp = (router) => {
  const setCurrentPageWithoutAssetsRefresh = (page) => {
    state.currentPage = page
  }

  const setSelectedCurrency = (currency) => {
    state.selectedCurrency = currency
  }

  const setCurrentPage = (page) => {
    state.currentPage = page
    setUriPage(router, page)
  }

  const setSortBy = (sortBy) => {
    state.sortBy = sortBy
    setUriSortBy(router, sortBy)
  }

  const setSortDirection = (sortDirection) => {
    state.sortDirection = sortDirection
    setUriSortDirection(router, sortDirection)
  }

  const setAggregates = async (repository) => {
    const { rates: newRates, assetNumber: newAssetNumber } = await repository.getAggregates()
    state.rates = newRates
    state.loadingRates = false
    state.assetNumber = newAssetNumber
  }

  const setAssetTokenIds = async (repository) => {
    const assetTokenIds = await repository.getAssetTokenIds()
    state.assetTokenIds = assetTokenIds
  }

  const setSelectedCollectionSlug = (slug) => {
    state.currentPage = 1
    state.selectedCollectionSlug = slug
    setUriSelectedCollectionSlug(router, slug)
  }

  const toggleSortDirection = () => {
    if (state.sortDirection === SortDirections.asc) {
      state.sortDirection = SortDirections.desc
    } else {
      state.sortDirection = SortDirections.asc
    }
    setUriSortDirection(router, state.sortDirection)
  }

  const setAssets = async (repository) => {
    assets.value = []
    updateStateFromUri()

    const options = {
      page: state.currentPage,
      sortBy: state.sortBy,
      sortDirection: state.sortDirection,
      collectionSlug: state.selectedCollectionSlug,
    }

    const { data, count } = await repository.getAssets(options)

    assets.value = data
    state.assetNumber = count
  }

  const setCollections = async (repository) => {
    const collections = await repository.getCollections()
    state.collections = collections
  }

  const updateStateFromUri = () => {
    state.currentPage = getUriPage()
    state.selectedCollectionSlug = getUriSelectedCollectionSlug(router)
    state.sortBy = getUriSortBy()
    state.sortDirection = getUriSortDirection()
  }

  // getters
  const ethInCurrency = () => {
    if (!state.rates.ETH) return null
    const priceInCents = state.rates.ETH[getRateBaseCurrency()]
    const price = Price.create(priceInCents, Tickers.USD)
    return price.pretty()
  }

  const btcInCurrency = () => {
    if (!state.rates.BTC) return null
    const priceInCents = state.rates.BTC[getRateBaseCurrency()]
    const price = Price.create(priceInCents, Tickers.USD)
    return price.pretty()
  }

  const xcpInCurrency = () => {
    if (!state.rates.XCP) return null
    const priceInCents = state.rates.XCP[getRateBaseCurrency()]
    const price = Price.create(priceInCents, Tickers.USD)
    return price.pretty()
  }

  const selectedBaseCurrencySymbol = () => {
    const currency = getRateBaseCurrency()
    return TickerToSymbol[currency] || ''
  }

  const selectedCollection = () => {
    return state.collections.find((collection) => collection.slug === state.selectedCollectionSlug)
  }

  const getPageFromUri = () => {
    return getUriPage()
  }

  // @RATES
  const getRateBaseCurrency = () => {
    const validCurrencies = [Tickers.USD, Tickers.EUR]
    const defaultCurrency = Tickers.USD
    const currency = validCurrencies.includes(state.selectedCurrency) ? state.selectedCurrency : defaultCurrency
    return currency
  }

  return {
    assets,
    state,
    setAggregates,
    setAssetTokenIds,
    setSelectedCollectionSlug,
    toggleSortDirection,
    setAssets,
    setCollections,
    updateStateFromUri,
    setCurrentPageWithoutAssetsRefresh,
    setSelectedCurrency,
    setCurrentPage,
    setSortBy,
    setSortDirection,
    ethInCurrency,
    btcInCurrency,
    xcpInCurrency,
    selectedBaseCurrencySymbol,
    selectedCollection,
    getPageFromUri,
  }
}
