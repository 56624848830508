import Axios from 'axios'
import { getBaseApiUrl } from './AppUrls'

class WebClient {
  static init() {
    return new WebClient()
  }

  constructor() {
    this.axios = Axios.create()
    this.baseUrl = getBaseApiUrl()
    this.routes = this.getRoutes()
  }

  getRoutes() {
    return {
      getAggregates: '/aggregates',
      getAsset: '/assets',
      getAssets: '/assets/get-assets',
      getAssetTokenIds: '/assets/asset-token-ids',
      getCollections: '/collections',
      getContributors: '/contributors',
      getGdpr: '/gdpr/latest',
      getHolders: '/holders',
      getHoldersLeaderboard: '/holders/leaderboard',
      getLatestAnnouncement: '/announcements/latest',
      getLatestListings: '/listings/latest',
      getLatestsSales: '/sales/latest',
      getListings: '/listings',
      getNextFeatures: '/next-features',
      getSales: '/sales',
    }
  }

  async post(args) {
    try {
      const { route, body, params } = args
      const res = await this.axios.post(`${this.baseUrl}${route}`, body, {
        params,
      })
      return res.data
    } catch (error) {
      if (args.route.indexOf('/login') > -1) {
        throw new Error(error.response?.data.message || 'Something went wrong')
      }
      return this.handleAxiosError(error)
    }
  }

  async put(args) {
    try {
      const { route, body, params } = args
      const res = await this.axios.put(`${this.baseUrl}${route}/${params.id}`, body)
      return res.data
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  async get(args) {
    try {
      const { route, params } = args
      const res = await this.axios.get(`${this.baseUrl}${route}`, { params })
      return res.data
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  async delete(args) {
    try {
      const { route, params } = args
      const res = await this.axios.delete(`${this.baseUrl}${route}/${params.id}`)
      return res.data
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  handleAxiosError(error) {
    const status = error.response?.status

    if (!status) {
      throw new Error(error)
    }

    const errorMessage = error.response?.data.message || error.response?.data || 'Something went wrong'
    throw new Error(errorMessage)
  }
}

export default WebClient
