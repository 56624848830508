// import router from '../router'

import {
  DefaultUriParamsValues,
  SortDirectionToMinimizedParam,
  UriParams,
  UriParamToMinimizedParam,
  UriSortDirections,
  UriSortFields,
  UriSortFieldsToMinimizedParam,
} from '../enums/UriParams'
import { defaultCollection } from '../constants/appConstants'

export const getUriPage = () => Number(getUriParam(UriParams.page))

export const getUriSelectedCollectionSlug = (router) => {
  return router.currentRoute._value.params?.collection || defaultCollection
}

export const getUriSortBy = () => UriSortFields[getUriParam(UriParams.sortBy)]

export const getUriSortDirection = () => UriSortDirections[getUriParam(UriParams.sortDirection)]

export const setUriPage = (router, page) => {
  const value = page || DefaultUriParamsValues[UriParams.page]
  if (value === DefaultUriParamsValues[UriParams.page]) {
    removeUriParam(router, UriParams.page)
    return
  }

  setUriParam(router, UriParams.page, value)
}

export const setUriSelectedCollectionSlug = (router, id) => {
  const value = id || defaultCollection
  const url = new URL(window.location.href)
  const path = url.pathname.split('/').pop()
  if (path === value) return
  if (value === defaultCollection) {
    url.pathname = '/explorer'
    pushUri(router, url)
    return
  }
  url.pathname = `/explorer/${value}`
  pushUri(router, url, value)
}

export const setUriSortBy = (router, v) => {
  const value = UriSortFieldsToMinimizedParam[v] || DefaultUriParamsValues[UriParams.sortBy]
  if (value === DefaultUriParamsValues[UriParams.sortBy]) {
    removeUriParam(router, UriParams.sortBy)
    return
  }

  setUriParam(router, UriParams.sortBy, value)
}

export const setUriSortDirection = (router, v) => {
  const value = SortDirectionToMinimizedParam[v] || DefaultUriParamsValues[UriParams.sortDirection]
  if (value === DefaultUriParamsValues[UriParams.sortDirection]) {
    removeUriParam(router, UriParams.sortDirection)
    return
  }

  setUriParam(router, UriParams.sortDirection, value)
}

const removeUriParam = (router, param) => {
  const minimizedParam = UriParamToMinimizedParam[param]
  var url = new URL(window.location.href)
  const searchParams = url.searchParams
  searchParams.delete(minimizedParam)
  pushUri(router, url, 0)
}

const getUriParam = (param) => {
  const minimizedParam = UriParamToMinimizedParam[param]
  var url = new URL(window.location.href)
  const searchParams = url.searchParams
  if (!searchParams.has(minimizedParam)) return DefaultUriParamsValues[param]
  return searchParams.get(minimizedParam)
}

const setUriParam = (router, param, value) => {
  const minimizedParam = UriParamToMinimizedParam[param]
  var url = new URL(window.location.href)
  const searchParams = url.searchParams
  searchParams.set(minimizedParam, value)
  pushUri(router, url, 0)
}

const pushUri = (router, uri, collection) => {
  if (!router) return

  const uriString = uri.toString()

  if (uriString === window.location.href) return

  if (collection) {
    router.push({ name: 'Explorer', params: { collection: collection } })
    return
  }
  router.push(`${uri.pathname}${uri.search}`)
}
