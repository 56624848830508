<template>
  <div class="latestsales">
    <h3 class="title-sales">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="currentColor"
        class="bi bi-cart-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
        />
      </svg>
      Latest sales
    </h3>
    <div class="latest-sales-container">
      <div class="sales-table">
        <div class="sales-table-row-header">
          <div class="sales-table-column-header-asset">
            <span>Asset</span>
          </div>
          <div class="sales-table-column-header">
            <span>Price</span>
          </div>
          <div class="sales-table-column-header">
            <span>Market</span>
          </div>
          <div class="sales-table-column-header">
            <span>Date</span>
          </div>
        </div>
      </div>
      <LatestSalesTableRow v-for="(sale, i) in sales" :key="`latest-sale-${i}`" :sale="sale" />
    </div>
  </div>
</template>

<script>
import LatestSalesTableRow from './LatestSalesTableRow.vue'
import { useRepository } from '../composables/useRepository'

export default {
  components: {
    LatestSalesTableRow,
  },

  data: () => ({
    sales: [],
  }),

  async created() {
    try {
      const repository = useRepository()
      this.sales = await repository.getLatestSales()
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style>
.latest_sales {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  min-width: 0;
  color: var(--white);
  background-color: var(--primary-color);
  border: 1px solid var(--grey-one);
  border-radius: var(--border-radius);
  margin: 0.5rem;
}

.title-sales {
  padding: 0.7rem 1rem;
  color: var(--white);
  font-size: 1rem;
}

.latest-sales-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5rem;
}

.sales-table {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  overflow-x: hidden;
  -webkit-border-top-left-radius: var(--border-radius);
  -webkit-border-top-right-radius: var(--border-radius);
  -moz-border-radius-topleft: var(--border-radius);
  -moz-border-radius-topright: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.sales-table-row-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--primary-color);
}

.sales-table-column-header-asset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.6rem;
  text-align: center;
  width: calc(100% / 3 * 1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  background-color: var(--grey-one);
  color: var(--white);
  text-transform: uppercase;
  font-weight: var(--font-bold);
  white-space: nowrap;
}

.sales-table-column-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.6rem;
  text-align: center;
  width: 10%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  background-color: var(--grey-one);
  color: var(--white);
  text-transform: uppercase;
  font-weight: var(--font-bold);
  white-space: nowrap;
}

.sales-table-header {
  display: flex;
}
</style>
