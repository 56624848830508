<template>
  <div class="address-detail-container">
    <div
      v-if="!loading && holder"
      class="address-detail-container"
    >
      <HolderOverview :holder="holder" />
      <h1>Holder's tokens</h1>
      <div class="holder-token-container">
        <HolderToken
          v-for="token in tokens"
          :key="token.tokenId"
          :token="token"
        />
      </div>
    </div>
    <div v-else-if="!loading && !holder">
      Holder not found
    </div>
    <LoadingOverlay v-else />
  </div>
</template>

<script>
import HolderToken from '../components/HolderToken.vue'
import LoadingOverlay from '../components/LoadingOverlay.vue'
import HolderOverview from '../components/HolderOverview.vue'
import { useRepository } from '../composables/useRepository'

export default {
  components: { HolderToken, HolderOverview, LoadingOverlay },

  data: () => ({
    holder: null,
    tokens: [],
    loading: true,
  }),

  computed: {
    address() {
      return this.$route.params.address
    },
  },

  async created() {
    try {
      const repository = useRepository()
      const { holder, tokens } = await repository.getHolder(this.address)
      this.holder = { ...holder, tokens }
      this.tokens = tokens
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  },
}
</script>

<style>
.address-detail-container {
  height: 100%;
  padding: 0.5rem;
}

.holder-token-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
  grid-auto-rows: auto;
}

@media screen and (max-width: 767px) {
  .address-detail-container {
    order: 5;
  }
}
</style>
