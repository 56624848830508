const {
  VUE_APP_API_PROTOCOL, //
  VUE_APP_API_HOST,
  VUE_APP_API_PORT,
} = process.env

export const getBaseApiUrl = () => {
  if (VUE_APP_API_PROTOCOL === 'http') {
    return `${VUE_APP_API_PROTOCOL}://${VUE_APP_API_HOST}:${VUE_APP_API_PORT}`
  }
  return `${VUE_APP_API_PROTOCOL}://${VUE_APP_API_HOST}`
}
