<template>
  <div class="contributor-card-container">
    <div class="contributor-description">
      <a v-if="contributor.twitter" class="contributor-social-link" :href="contributor.twitter" target="_blank">
        <div class="contributor-header">
          <img :src="contributor.picture" :alt="contributor.name" class="contributor-avatar">
          <div class="contributor-information">
            <p class="contributor-name">
              {{ contributor.name }}
            </p>
          </div>
        </div>
      </a>
      <div v-else class="contributor-header">
        <img :src="contributor.picture" :alt="contributor.name" class="contributor-avatar">
        <div class="contributor-information">
          <p class="contributor-name">
            {{ contributor.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['contributor'],
}
</script>

<style>
.contributor-card-container {
  width: 100%;
  padding: 0.7rem;
  border: 1px solid var(--grey-one);
}

.contributor-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contributor-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
}

.contributor-name {
  font-size: 1rem;
  font-weight: var(--font-bold);
  margin-bottom: 2px;
}

.contributor-secondary-information {
  font-size: 0.8rem;
  color: var(--grey-two);
  font-weight: 300;
  padding-bottom: 1px;
}

.contributor-social-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.contributor-social-link:hover {
  cursor: pointer;
  color: var(--grey-four);
}

.contributor-description-text {
  padding-top: 20px;
  font-weight: 300;
  font-style: italic;
}
</style>
