<template>
  <div class="leaderboard-tab-container">
    <div :class="{ selectedTab: tab1, otherTab: !tab1 }" @click="onRankingChange('last-sale-valuation')">
      Last sale valuation
    </div>
    <div :class="{ selectedTab: tab2, otherTab: !tab2 }" @click="onRankingChange('floor-valuation')">
      Floor valuation
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab1: true,
    tab2: false,
  }),

  computed: {
    currentRanking() {
      if (!this.$route.query.ranking) return 'last-sale-valuation'
      return String(this.$route.query.ranking) || 'last-sale-valuation'
    },
  },

  created() {
    this.currentRanking
    this.checkRanking()
  },

  methods: {
    onRankingChange(ranking) {
      if (this.currentRanking === ranking) return
      this.$emit('onRankingChange', ranking)
    },

    checkRanking() {
      if (this.currentRanking === 'last-sale-valuation') {
        this.tab1 = true
        this.tab2 = false
      } else {
        this.tab1 = false
        this.tab2 = true
      }
    },
  },
}
</script>

<style>
.leaderboard-tab-container div:last-child {
  margin-left: 24px;
}

.otherTab {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 15px;
  background-color: var(--primary-color);
  border-bottom: 1px solid var(--grey-one);
}

.otherTab:hover {
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius);
  background-color: var(--grey-one);
}

.selectedTab {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 15px;
  border-radius: var(--border-radius);
  background-color: var(--grey-one);
  border: 1px solid var(--grey-one);
}
</style>
