<template>
  <div class="holder-overview-rank-container">
    <HolderRankLastSaleTag :rank="getLastSaleRank()" />
    <HolderRankFloorValuationTag :rank="getFloorValuationRank()" />
  </div>
  <div class="holder-overview-container">
    <!--<img
      v-if="!!holder.tokens[0].pictures"
      class="holder-overview-picture"
      :src="`${apiUrl}/images/${getPictureFromPictures(holder.tokens[0].pictures)}`"
      :alt="holder.tokens[0].tokenId"
    />-->
    <div class="holder-data">
      <h2 class="holder-overview-address">
        {{ holder.address }}
      </h2>
      <p class="holder-overview-stats">Floor valuation {{ displayUsdFloorValuation }}</p>
      <p class="holder-overview-stats">Last sale valuation {{ displayUsdLastSalesValuation }}</p>
      <p class="holder-overview-stats">
        {{ holder.tokens.length }} {{ holder.tokens.length > 1 ? 'unique tokens' : 'unique token' }}
      </p>
      <a :href="`https://tokenscan.io/address/${holder.address}`" target="_blank" rel="noopener noreferrer">
        <img class="holder-overview-xchain" src="https://tokenscan.io/img/header-xchain-logo.png" />
      </a>
    </div>
  </div>
</template>

<script>
import { getBaseApiUrl } from '../services/AppUrls'
import BN from '../services/BigNumber.js'
import HolderRankFloorValuationTag from './tags/HolderRankFloorValuationTag.vue'
import HolderRankLastSaleTag from './tags/HolderRankLastSaleTag.vue'

export default {
  components: {
    HolderRankFloorValuationTag,
    HolderRankLastSaleTag,
  },

  props: ['holder'],

  data: () => ({
    apiUrl: getBaseApiUrl(),
  }),

  computed: {
    displayUsdFloorValuation() {
      return `$${new BN(this.holder.floor_price_usd_valuation).toFormat({ maximumDecimalPlaces: 2 })}`
    },

    displayUsdLastSalesValuation() {
      return `$${new BN(this.holder.last_sales_price_usd_valuation).toFormat({ maximumDecimalPlaces: 2 })}`
    },
  },

  methods: {
    getPictureFromPictures(pictures) {
      return pictures.split(';').shift()
    },

    getLastSaleRank() {
      return this.holder.last_sales_price_usd_valuation_rank
    },

    getFloorValuationRank() {
      return this.holder.floor_price_usd_valuation_rank
    },
  },
}
</script>

<style>
.holder-overview-rank-container {
  margin-top: 30px;
}
.holder-overview-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: --grey-four;
  border-radius: 4px;
  padding-bottom: 20px;
}

.holder-overview-picture {
  object-fit: scale-down;
  border-radius: 4px;
  width: 200px;
  height: 250px;
}

.holder-data {
  width: 100%;
}

.holder-overview-rank-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  padding-bottom: 20px;
}

.holder-overview-address {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2rem;
  padding-bottom: 20px;
}

.holder-overview-stats {
  padding-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 500;
}

.holder-overview-xchain {
  padding-top: 10px;
  width: 40px;
}

@media (max-width: 1000px) {
  .holder-overview-container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .holder-overview-picture {
    flex: 1;
  }

  .holder-data {
    flex: 2;
  }
}

@media (max-width: 767px) {
  .holder-overview-container {
    flex-direction: column;
    margin: auto;
    padding: 10px;
  }

  .holder-overview-picture {
    flex: 1;
    width: 120px;
    height: 120px;
    margin: auto;
  }

  .holder-data {
    flex: 2;
  }

  .holder-overview-address {
    font-size: 0.9rem;
  }

  .holder-overview-rank-container {
    flex-direction: row;
  }
}
</style>
