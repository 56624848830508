const AssetTableFields = {
  name: 'name',
  floor: 'floor',
  lastSale: 'lastSale',
  supply: 'supply',
  marketCap: 'marketCap',
  top3Holders: 'top3Holders',
  forSale: 'forSale',
  creationDate: 'creationDate',
}

export default AssetTableFields
