<template>
  <div
    class="content pie-chart"
    style="overflow: hidden"
  >
    <div class="sub-section">
      <div class="sub-section-header">
        <PersonIcon />
        Assets repartition -
        <a
          style="cursor: pointer; text-transform: lowercase; padding: 0; margin: 0; border: 0"
          @click="toggleChartDataMode"
        >
          [{{ chartDataMode === ChartDataModes.detailed ? 'Show simple view' : 'Show detailed view' }}]
        </a>
        <a
          v-if="salesChartData && asset.divisible && windowWidth >= 650"
          style="cursor: pointer; text-transform: lowercase; padding: 0; margin: 0; border: 0"
          @click="toggleShowFractional"
        >
          &nbsp; [{{ showFractional ? 'Whole cards only' : 'Show fractional cards' }}]
        </a>
      </div>
      <div
        class="holders-pie-chart"
        style="overflow: hidden"
      >
        <highcharts
          v-if="salesChartData && windowWidth >= 650"
          constructor-type="stockChart"
          :options="salesChartData"
        />
        <table v-if="salesChartData && windowWidth < 650">
          <thead>
            <tr class="header">
              <th>Type</th>
              <th
                style="text-decoration: underline"
                @click="toggleShowFractional"
              >
                {{ showFractional ? 'Whole cards' : 'All cards' }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="[type, quantity] in Object.entries(getChartData())"
              :key="`h${type}`"
            >
              <td>{{ type }}</td>
              <td>
                {{ quantity }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { AssetHolderTypes } from '../enums/AssetHolderTypes'
import PersonIcon from './icons/PersonIcon.vue'
import BN from '../services/BigNumber'
import BigNumber from '../services/BigNumber'

const ChartDataModes = {
  binary: 'binary',
  detailed: 'detailed',
}

const RepartitionCategories = {
  dispensers: 'Dispensers',
  vaults: 'Vaults',
  active: 'Active',
  inactiveSinceEVCreation: 'Inactive since Emblem Vault creation',
  noDebit: 'No debit',
  noDebitSinceEVCreation: 'No debit - created after Emblem Vault creation',
}

export default {

  components: {
    PersonIcon,
  },
  props: ['holders', 'asset'],

  data: () => ({
    ChartDataModes,
    chartDataMode: ChartDataModes.binary,
    windowWidth: window.innerWidth,
    showFractional: false,
    salesChartData: null,
  }),

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },

  async mounted() {
    this.setChartData()

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  methods: {
    setChartData() {
      const data = this.getChartData()

      this.salesChartData = {
        styledMode: true,
        navigator: { enabled: false },
        chart: {
          marginTop: 0,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: '350px',
        },
        title: { text: null },
        credits: { enabled: false },
        tooltip: { enabled: false },
        xAxis: { visible: false },
        accessibility: { point: { valueSuffix: '%' } },
        plotOptions: {
          pie: {
            width: '100%',
            minSize: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            center: ['50%', '50%'],
            dataLabels: {
              enabled: false,
              format: '<b>{point.name}: </b>{point.y:1f} ({point.percentage:.1f}%)',
            },
          },
        },
        series: [
          {
            data: Object.entries(data).map(([name, value]) => ({ name, y: value })),
            allowPointSelect: false,
            marker: { enabled: true },
            showInLegend: false,
            borderWidth: 0.1,
            color: 'rgba(255, 255, 255, 0.8)',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}: </b>{point.y:1f} ({point.percentage:.1f}%)',
              style: { textOutline: 'none', color: 'white' },
            },
          },
        ],
      }
    },

    getChartData() {
      if (this.chartDataMode === ChartDataModes.binary) {
        return this.getSimpleChartData()
      }
      return this.getDetailedChartData()
    },

    getSimpleChartData() {
      const initialData = {
        [RepartitionCategories.active]: '0',
        [RepartitionCategories.inactiveSinceEVCreation]: '0',
      }

      const holders = this.holders.reduce((acc, holder) => {
        const { quantity, last_outgoing_transaction, first_incoming_transaction, type, is_vault } = holder

        let qty = new BN(0)

        if (this.showFractional) {
          qty = new BN(quantity)
        } else {
          qty = new BN(quantity).integerValue(BigNumber.ROUND_FLOOR)
        }

        const isLastTxSinceEV = this.isAfterDate(new Date('2020-08-31'), new Date(last_outgoing_transaction))
        const isFirstIncomingTxSinceEV = this.isAfterDate(new Date('2020-08-31'), new Date(first_incoming_transaction))

        // @TODO: Why vault is not a type?
        if (type === AssetHolderTypes.dispenser || is_vault) {
          return {
            ...acc,
            [RepartitionCategories.active]: qty.plus(acc[RepartitionCategories.active]).toString(),
          }
        }

        if (isLastTxSinceEV || isFirstIncomingTxSinceEV) {
          acc[RepartitionCategories.active] = qty.plus(acc[RepartitionCategories.active]).toString()
        } else {
          acc[RepartitionCategories.inactiveSinceEVCreation] = qty
            .plus(acc[RepartitionCategories.inactiveSinceEVCreation])
            .toString()
        }

        return acc
      }, initialData)

      return this.getMapWithNumberValues(holders)
    },

    isAfterDate(date, transaction) {
      if (!transaction) return false
      return transaction.getTime() > date.getTime()
    },

    getMapWithNumberValues(map) {
      Object.keys(map).forEach((key) => {
        const value = map[key]
        if (value === '0') {
          delete map[key]
        } else {
          map[key] = Number(new BN(map[key]).toFixed(2))
        }
      })

      return map
    },

    getDetailedChartData() {
      const data = {
        [RepartitionCategories.active]: '0',
        [RepartitionCategories.dispensers]: '0',
        [RepartitionCategories.vaults]: '0',
        [RepartitionCategories.inactiveSinceEVCreation]: '0',
        [RepartitionCategories.noDebit]: '0',
        [RepartitionCategories.noDebitSinceEVCreation]: '0',
      }

      const holders = this.holders.reduce((acc, holder) => {
        const { quantity, last_outgoing_transaction, first_incoming_transaction, type, is_vault } = holder

        let qty = new BN(0)

        if (this.showFractional) {
          qty = new BN(quantity)
        } else {
          qty = new BN(quantity).integerValue(BigNumber.ROUND_FLOOR)
        }

        const isLastTxSinceEV = this.isAfterDate(new Date('2020-08-31'), new Date(last_outgoing_transaction))
        const isFirstIncomingTxSinceEV = this.isAfterDate(new Date('2020-08-31'), new Date(first_incoming_transaction))

        if (type === AssetHolderTypes.dispenser) {
          return {
            ...acc,
            [RepartitionCategories.dispensers]: qty.plus(acc[RepartitionCategories.dispensers]).toString(),
          }
        }

        if (is_vault) {
          return { ...acc, [RepartitionCategories.vaults]: qty.plus(acc[RepartitionCategories.vaults]).toString() }
        }

        if (isFirstIncomingTxSinceEV && !isLastTxSinceEV) {
          return {
            ...acc,
            [RepartitionCategories.noDebitSinceEVCreation]: qty
              .plus(acc[RepartitionCategories.noDebitSinceEVCreation])
              .toString(),
          }
        } else if (last_outgoing_transaction === null) {
          return { ...acc, [RepartitionCategories.noDebit]: qty.plus(acc[RepartitionCategories.noDebit]).toString() }
        }

        if (isLastTxSinceEV) {
          acc[RepartitionCategories.active] = qty.plus(acc[RepartitionCategories.active]).toString()
        } else {
          acc[RepartitionCategories.inactiveSinceEVCreation] = qty
            .plus(acc[RepartitionCategories.inactiveSinceEVCreation])
            .toString()
        }

        return acc
      }, data)

      return this.getMapWithNumberValues(holders)
    },

    toggleChartDataMode() {
      const newMode = this.chartDataMode === ChartDataModes.binary ? ChartDataModes.detailed : ChartDataModes.binary
      this.chartDataMode = newMode
      this.setChartData()
    },

    onResize() {
      this.windowWidth = window.innerWidth
    },

    toggleShowFractional() {
      this.showFractional = !this.showFractional
      this.setChartData()
    },
  },
}
</script>

<style>
.highcharts-background {
  fill: transparent;
}

.highcharts-pie-series .highcharts-point {
  stroke: #ede;
  stroke-width: 2px;
}

.highcharts-pie-series .highcharts-data-label-connector {
  stroke: silver;
  stroke-dasharray: 2, 2;
  stroke-width: 2px;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 600px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-scrollbar {
  display: none;
}

@media only screen and (max-width: 1450px) {
  .pie-chart {
    padding: 0 !important;
    border: 0 !important;
    border-radius: 4px;
    margin-top: 8px;
  }

  .pie-chart .sub-section-header {
    text-align: left;
  }
}

@media only screen and (max-width: 650px) {
  .pie-chart .holders-pie-chart {
    padding: 8px !important;
    margin-top: 8px;
  }

  .holders-pie-chart th {
    border-radius: 0 !important;
  }
}
</style>
