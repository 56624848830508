<template>
  <Pagination
    :page-count="totalPages"
    :current-page="currentPage"
    @changePage="onPageChange"
  />
</template>

<script>
import Pagination from './Pagination.vue'

export default {
  components: {
    Pagination,
  },

  props: ['currentPage', 'totalPages'],

  methods: {
    onPageChange(page) {
      if (page <= 0) page = 1
      if (page >= this.totalPages) page = this.totalPages
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.$emit('onPageChange', page)
    },
  },
}
</script>

<style></style>
