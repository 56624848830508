<template>
  <div class="fundable-tag-container">
    <div class="fundable-tag-text">
      Fundable
    </div>
  </div>
</template>

<script>
export default {
  name: 'FundableTag',
}
</script>

<style>
.fundable-tag-container {
  background-color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  transition: 0.3s;
}

.fundable-tag-container:hover {
  scale: 1.2;
}

.fundable-tag-text {
  color: #252525;
  font-size: 0.8rem;
}
</style>
