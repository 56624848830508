<template>
  <div id="holders">
    <h3>
      <PersonIcon />
      XCP Holders
    </h3>

    <div class="asset-holders-inner-container">
      <AssetHoldersTable :asset="asset" :holders="holders" />
      <AssetHoldersPieChart :holders="holders" :asset="asset" />
    </div>
  </div>
</template>

<script>
import AssetHoldersTable from './AssetHoldersTable.vue'
import AssetHoldersPieChart from './AssetHoldersPieChart.vue'
import PersonIcon from './icons/PersonIcon.vue'

export default {

  components: {
    AssetHoldersTable,
    AssetHoldersPieChart,
    PersonIcon,
  },
  props: ['asset', 'holders'],
}
</script>

<style>
.asset-holders-inner-container {
  display: grid;
  grid-template-columns: minmax(570px, auto) 1fr;
}

@media only screen and (max-width: 1450px) {
  .asset-holders-inner-container {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }
}
</style>
