<template>
  <router-link v-if="holder" :to="{ name: 'Address Detail', params: { address: holder.address } }">
    <div class="card pt-8">
      <img
        v-if="!!holder.tokens[0].pictures"
        class="holder-picture"
        :src="`${apiUrl}/images/${getPictureFromPictures(holder.tokens[0].pictures)}`"
        :alt="holder.tokens[0].tokenId"
      />
      <img v-else class="holder-picture" :src="`${apiUrl}/images/PLACEHOLDER.png`" />
      <div class="holder-overview">
        <h2 class="holder-rank mt-0 mb-8">#{{ rank }}</h2>
        <h2 class="holder-address">
          {{ holder.address }}
        </h2>

        <p class="holder-stats">Floor valuation {{ displayUsdFloorValuation }}</p>
        <p class="holder-stats">Last sale valuation {{ displayUsdLastSalesValuation }}</p>
        <p class="holder-stats">
          {{ holder.tokens.length }} {{ holder.tokens.length > 1 ? 'unique tokens' : 'unique token' }}
        </p>

        <div class="holder-tokens-overview mb-8">
          <div v-for="token in tokenOverview" :key="token.tokenId">
            <img
              v-if="token.pictures"
              class="holder-picture-overview"
              :src="`${apiUrl}/images/${getPictureFromPictures(token.pictures)}`"
              :alt="token.tokenId"
            />
            <img v-else class="holder-picture-overview" :src="`${apiUrl}/images/PLACEHOLDER.png`" />
          </div>
          <p v-if="holder.tokens.length > 9" class="secondary-text ml-4" style="place-self: center">...</p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { getBaseApiUrl } from '../services/AppUrls'
import BN from '../services/BigNumber.js'

export default {
  props: ['holder', 'rank'],

  data: () => ({
    apiUrl: getBaseApiUrl(),
  }),

  computed: {
    tokenOverview() {
      if (this.holder.tokens.length === 1) return [this.holder.tokens[0]]
      return this.holder.tokens.filter((x, i) => i > 0 && i < 11)
    },

    displayUsdFloorValuation() {
      return `$${new BN(this.holder.floor_price_usd_valuation).toFormat({ maximumDecimalPlaces: 2 })}`
    },

    displayUsdLastSalesValuation() {
      return `$${new BN(this.holder.last_sales_price_usd_valuation).toFormat({ maximumDecimalPlaces: 2 })}`
    },
  },

  methods: {
    getPictureFromPictures(pictures) {
      return pictures.split(';').shift()
    },
  },
}
</script>

<style>
.card {
  margin: auto;
  background-color: --grey-four;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.card:hover {
  background-color: #454545;
}

.holder-picture {
  display: block;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  height: auto;
  object-fit: contain;
  object-position: center;
  margin-right: 0.5rem;
}

.holder-rank {
  color: rgb(255, 255, 255);
}

.holder-address {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2rem;
  padding-bottom: 20px;
}

.holder-overview {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-top: 10px;
  overflow: hidden;
}

.holder-stats {
  padding-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 500;
}

.holder-tokens-overview {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
  padding-top: 20px;
}

.holder-tokens-overview div {
  margin-right: 4px;
}

.holder-tokens-overview div:last-child {
  margin-right: 0px;
}

.holder-picture-overview {
  border-radius: 4px;
  max-width: 50px;
  max-height: 50px;
}

@media (max-width: 1000px) {
  .card {
    padding: 10px;
  }

  .holder-overview {
    flex: 2;
  }

  .see-more-tokens {
    display: none;
  }
}

@media (max-width: 767px) {
  .card {
    margin: auto;
    padding: 10px;
  }

  .holder-picture {
    max-width: 100px;
  }

  .holder-overview {
    flex: 2;
    padding-left: 5px;
  }

  .holder-tokens-overview {
    display: none;
  }

  .see-more-tokens {
    display: none;
  }
}
</style>
