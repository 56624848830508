<template>
  <div class="pagination">
    <div class="pagination-button-wrapper">
      <button class="pagination-button" @click="decrement()">
        <ArrowNavigation style="height: 13px" /> Prev.
      </button>
    </div>

    <div class="pagination-page">
      <span class="pagination-text">Page:</span>
      <input
        v-model="page"
        class="pagination-input"
        type="number"
        min="1"
        :max="pageCount"
        pattern="[0-9]"
        @keyup.enter="handleInput"
      >
      <span class="pagination-text">of {{ pageCount.toLocaleString() }}</span>
    </div>

    <div class="pagination-button-wrapper pagination-flex-end">
      <button class="pagination-button next-button" @click="increment()">
        Next <ArrowNavigation style="height: 13px; transform: rotate(180deg)" />
      </button>
    </div>
  </div>
</template>

<script>
import ArrowNavigation from './icons/ArrowNavigation.vue'

export default {
  components: {
    ArrowNavigation,
  },

  props: ['pageCount', 'currentPage'],

  setup(props, context) {
    const increment = () => {
      context.emit('changePage', props.currentPage + 1)
    }

    const decrement = () => {
      context.emit('changePage', props.currentPage - 1)
    }

    return {
      increment,
      decrement,
    }
  },

  data() {
    return {
      page: this.currentPage,
    }
  },

  computed: {
    showPrevButton() {
      return this.currentPage > 1 ? true : false
    },

    showNextButton() {
      return this.currentPage < this.pageCount ? true : false
    },
  },

  methods: {
    handleInput() {
      const value = this.page
      if (value > 0 && value <= this.pageCount) {
        this.$emit('changePage', Number(value))
      }
    },
  },
}
</script>

<style>
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-items: center;
  white-space: nowrap!important;
}

.pagination-button-wrapper {
  display: flex;
  flex: 1;
}

.hidden {
  display: none;
}

.pagination-button {
  width: 80px;
  height: 40px;
  background-color: var(--grey-one);
  box-shadow: none;
  border: 1px solid var(--grey-one);
  border-radius: var(--border-radius);
  color: var(--white);
  font-size: 0.8rem;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: 0 6px;
  gap: 10px;
  transition: 0.2s;
}

.pagination-button:hover {
  background-color: var(--primary-color);
}

.next-button {
  justify-content: flex-end;
}

.pagination-flex-end {
  justify-content: flex-end;
}

.pagination-page {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.pagination-text {
  margin: 0 10px;
}

.pagination-input {
  width: 50px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  background-color: var(--primary-color);
  box-shadow: none;
  border: 1px solid var(--grey-one);
  border-radius: var(--border-radius);
  color: var(--white);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 500px) {
  .pagination {
    flex-direction: column;
  }

  .pagination-button-wrapper {
    margin-bottom: 10px;
  }

  .pagination-page {
    margin-bottom: 10px;
  }
}
  
</style>
