<template>
  <div class="navigation-bar" :class="{ 'navigation-bar-hidden': !showNavbar }" @scroll="onScroll">
    <a href="/" class="menu-logo" title="HNFT Explorer">
      <img class="logo-navigation-bar" :src="logo" alt="HNFT Explorer" />
    </a>
    <div class="mobile-navigation">
      <div id="smallScreenNav" class="overlay">
        <a href="javascript:void(0)" class="close-button" @click="closeNavigation">&times;</a>
        <div class="overlay-content">
          <SearchBar class="search-bar-mobile" />
          <router-link :to="{ name: 'Home' }" @:click="linkClicked"> Home </router-link>
          <router-link :to="{ name: 'Explorer' }" @:click="linkClicked"> Explorer </router-link>
          <router-link :to="{ name: 'Leaderboard' }" @:click="linkClicked"> Leaderboard </router-link>
          <a href="https://timeline.hnft.wtf" target="_blank">Timeline</a>
          <a href="https://nmc.vision" target="_blank">Namecoin Vision</a>
        </div>
      </div>
      <span class="open-overlay-button" @click="openNavigation">&#9776;</span>
    </div>
    <div class="main-navigation">
      <ul class="navigation-list">
        <router-link :to="{ name: 'Home' }" @:click="linkClicked">
          <li class="navigation-item">
            <a class="label">Home</a>
          </li>
        </router-link>
        <router-link :to="{ name: 'Explorer' }" @:click="linkClicked">
          <li class="navigation-item">
            <a class="label">Explorer</a>
          </li>
        </router-link>
        <router-link :to="{ name: 'Leaderboard' }" @:click="linkClicked">
          <li class="navigation-item">
            <a class="label">Leaderboard</a>
          </li>
        </router-link>
        <li class="navigation-item">
          <a class="label" href="https://timeline.hnft.wtf" target="_blank">Timeline</a>
        </li>
        <li class="navigation-item">
          <a class="label" href="https://nmc.vision" target="_blank">Namecoin Vision</a>
        </li>
      </ul>
    </div>

    <div class="navigation-right">
      <SearchBar />
    </div>
  </div>
</template>

<script>
import logo from '../../public/images/HNFT_Logo_light_single.svg'
import SearchBar from './SearchBar.vue'

export default {
  components: { SearchBar },

  data: () => ({
    showNavbar: true,
    lastScrollPosition: 0,
    logo,
  }),

  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    enableScroll() {
      document.body.style.overflow = 'auto'
      document.getElementById('section_one').style.display = 'flex'
    },

    disableScroll() {
      document.body.style.overflow = 'hidden'
      document.getElementById('section_one').style.display = 'none'
    },

    onScroll() {
      const currentScrollPosition = window.scrollYOffset || document.documentElement.scrollTop

      if (currentScrollPosition < 70) {
        return
      }
      if (document.getElementById('smallScreenNav').style.height != '100vh') {
        this.showNavbar = currentScrollPosition < this.lastScrollPosition
        this.lastScrollPosition = currentScrollPosition
      }
    },

    openNavigation() {
      document.getElementById('smallScreenNav').style.height = '100vh'
      this.disableScroll()
    },

    closeNavigation() {
      document.getElementById('smallScreenNav').style.height = '0%'
      this.enableScroll()
    },

    linkClicked() {
      this.enableScroll()
    },
  },
}
</script>

<style>
.search-bar-mobile {
  width: 200px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.open-overlay-button {
  display: flex;
  flex-direction: row;
  padding-right: 25px;
  margin-left: auto;
  font-size: 30px;
  float: right;
}

.overlay {
  height: 0%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #252525;
  overflow: hidden;
}

.overlay-content {
  position: absolute;
  width: 100%;
  text-align: center;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 1.5rem;
  color: var(--white);
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .close-button {
  position: absolute;
  right: 45px;
  font-size: 47px;
  z-index: 5000;
}

.navigation-bar {
  z-index: 20;
  position: sticky;
  width: 100%;
  display: flex;
  background-color: var(--primary-color);
  align-items: center;
  min-height: 80px;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: 0.2s ease-out;
  box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
}

.navigation-bar-hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

.logo-navigation-bar {
  margin-left: 20px;
  margin-right: 20px;
  height: 60px;
}

.drop-button {
  height: 100%;
  cursor: pointer;
  background-color: var(--grey-one);
  color: white;
  font-weight: var(--font-bold);
}

.drop-button:hover,
.drop-button:focus {
  color: var(--grey-one);
}

.navigation-list {
  height: 100%;
  display: grid;
  align-items: center;
  list-style: none;
  width: 100%;
  grid-auto-flow: column;
  justify-content: left;
}

.navigation-list li {
  height: 100%;
  text-transform: uppercase;
}

.navigation-item {
  margin: 0 10px;
  font-weight: var(--font-bold);
  display: grid;
  place-content: center;
  text-align: center;
}

.label {
  transition: 0.2s ease-out;
}

.label:hover {
  color: #656565;
}

.mobile-social-link {
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 30px;
  place-content: center;
}

.navigation-right {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  margin-left: auto;
}

.social-link {
  display: grid;
  margin-left: 10px;
  color: var(--white);
  place-items: center;
  transition: 0.2s ease-out;
}

.social-link:hover {
  color: var(--grey-one);
}

@media screen and (max-width: 1000px) {
  .navigation-right {
    display: none;
  }

  .navigation-list {
    grid-auto-flow: row;
    grid-auto-rows: 40px;
    row-gap: 4px;
  }

  .main-navigation {
    display: none;
  }

  .navigation-bar {
    justify-content: space-between;
  }

  .social-link svg {
    height: 30px;
    width: 30px;
  }
}

@media screen and (min-width: 1000px) {
  .mobile-navigation {
    display: none;
  }
}
</style>
