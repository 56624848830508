<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2000 1240"
    width="30"
    height="30"
  >
    <path
      d="M2000,1073.32L1708.91,1364.6H1432.34a56.76,56.76,0,0,0-56.64,56.64v166a33.34,33.34,0,0,1-33.36,32.76H1131.18a33.34,33.34,0,0,1-33.36-33.36V1183.38l172.86-172.85a33.36,33.36,0,0,1,46.36.6l76.3,76.3h200l129-128.91V789.3L1590.18,656.56h-177.8L705.54,1363.39H293.6L0,1070V674.1L293.91,380.2H695.66L869.83,554.36a33.42,33.42,0,0,1,0,46.26L721.77,748.18a32.69,32.69,0,0,1-46.06,3.33c-0.81-.71-1.61-1.51-2.42-2.22l-92.32-92H408.61L277.08,788.8V955.5L408.2,1086.83H590.74L1297.69,380H1705l295,295v398.32Z"
      fill="#ec1550"
    />
  </svg>
</template>

<script>
export default {}
</script>
