<template>
  <div id="section_six" class="section">
    <div v-if="asset" id="asset" style="margin-top: 2rem">
      <div class="left">
        <div v-if="assetPicture" class="asset_image">
          <img :src="assetPicture" :alt="asset.tokenId">
          <div v-if="asset.collections.length > 1" class="image_toggle" @click="onImageToggleClicked()">
            <ImageToggleIcon />
          </div>
        </div>
        <div id="collections">
          <h3 :style="!assetPicture && 'margin-top:0;'">
            <BoxIcon />
            {{ asset.collections.length > 1 ? 'Collections' : 'Collection' }}
          </h3>
          <div class="content">
            <span
              v-for="(collection, i) in asset.collections"
              :key="`collection${i}`"
              :style="getCollectionStyle(collection, i)"
              @click="selectedCollectionName = collection"
            >{{ collection }}</span>
          </div>
        </div>

        <div v-if="asset.comments">
          <h3>
            <InformationIcon />
            Comments
          </h3>

          <div class="content">
            <span>{{ asset.comments }} {{ selectedCollectionName }}</span>
          </div>
        </div>
        <AssetInformationTable v-if="!loading" :asset="asset" :holders="holders" />
      </div>

      <div class="right">
        <div class="asset_image">
          <img v-if="assetPicture" :src="asset.getPicture(selectedCollectionName)" :alt="asset.tokenId">
          <div v-if="asset.collections.length > 1" class="image_toggle" @click="onImageToggleClicked()">
            <ImageToggleIcon />
          </div>
        </div>
        <div id="item_name">
          <h3>
            {{ asset.tokenId }}
            <span class="last_update">Last update: {{ getTimestampFromSqlTimestamp(asset.updatedAt) }}</span>
          </h3>
        </div>
        <div id="current_floor_price">
          <h3>
            <TagIcon />
            Current floor price
          </h3>
          <div class="content">
            <div class="floor_price">
              {{ asset.getPrettyFloorPrice() }}
              <span>({{ asset.getPrettyFloorPriceInCurrency(state.selectedCurrency) }})</span>
            </div>
            <AssetDetailButtons :asset="asset" :floor-listing="listings[0]" />
          </div>
        </div>
        <div id="historical_price">
          <h3>
            <TimeIcon />
            Historical price
          </h3>
          <div class="content">
            <AssetSalesChart v-if="!loading" :sales="sales" />
          </div>
        </div>
        <AssetHoldersInformation v-if="!loading" :asset="asset" :holders="holders" />
        <AssetListings :listings="listings" />
        <AssetSales :sales="sales" />
      </div>
    </div>
  </div>
</template>

<script>
import BN from 'bignumber.js'

import { getTimestampFromSqlTimestamp } from '../services/dateHelpers'
import Asset from '../models/Asset'
import Listing from '../models/Listing'

import AssetDetailButtons from '../components/AssetDetailButtons.vue'
import AssetHoldersInformation from '../components/AssetHoldersInformation.vue'
import AssetInformationTable from '../components/AssetInformationTable.vue'
import AssetListings from '../components/AssetListings.vue'
import AssetSales from '../components/AssetSales.vue'
import AssetSalesChart from '../components/AssetSalesChart.vue'
import BoxIcon from '../components/icons/BoxIcon.vue'
import ImageToggleIcon from '../components/icons/ImageToggleIcon.vue'
import InformationIcon from '../components/InformationIcon.vue'
import TagIcon from '../components/icons/TagIcon.vue'
import TimeIcon from '../components/icons/TimeIcon.vue'
import { nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { useApp } from '../composables/useApp'
import { useRepository } from '../composables/useRepository'

export default {
  components: {
    AssetDetailButtons,
    AssetHoldersInformation,
    AssetInformationTable,
    AssetListings,
    AssetSales,
    AssetSalesChart,
    BoxIcon,
    ImageToggleIcon,
    InformationIcon,
    TagIcon,
    TimeIcon,
  },

  setup() {
    const router = useRouter()
    const { state } = useApp(router)

    return {
      router,
      state,
    }
  },
  data: () => ({
    tokenId: null,
    selectedCollectionName: null,
    loading: true,
    asset: null,
    sales: [],
    listings: [],
    holders: [],
    getTimestampFromSqlTimestamp,
  }),

  computed: {
    assetPicture() {
      return this.asset.getPicture(this.selectedCollectionName)
    },
  },

  async created() {
    await nextTick()
    this.tokenId = this.$route.params.tokenId
    await this.initializeData()
  },

  methods: {
    async setAsset() {
      await this.fetchAsset()
    },

    async fetchAsset() {
      const repository = useRepository()
      const asset = await repository.getAsset(this.tokenId)
      this.asset = new Asset(asset, this.state.rates)
    },

    onImageToggleClicked() {
      const selectedCollectionName = this.asset.collections.indexOf(this.selectedCollectionName)
      const nextCollectionName = this.asset.collections[(selectedCollectionName + 1) % this.asset.collections.length]
      this.selectedCollectionName = nextCollectionName
    },

    getCollectionStyle(collection, index) {
      if (this.asset.collections.length < 2) {
        return 'font-weight: bold;'
      }

      let style = 'cursor: pointer; '

      if (!this.isSelectedCollectionIncludedInAssetCollection()) {
        if (index === 0) return (style += 'font-weight: 700;')
        return style
      }

      if (collection === this.selectedCollectionName) return (style += 'font-weight: 700;')

      return style
    },

    isSelectedCollectionIncludedInAssetCollection() {
      return this.asset.collections.includes(this.selectedCollectionName)
    },

    async setListings() {
      const repository = useRepository()
      const listingDTOs = await repository.getAssetListings(this.asset.tokenId)
      const listings = listingDTOs.map((dto) => new Listing(dto, this.state.rates))
      this.listings = listings.sort((a, b) => (new BN(a.usdPrice).gt(b.usdPrice) ? 0 : -1))
    },

    async initializeData() {
      try {
        this.loading = true
        this.asset = null
        this.sales = []
        this.listings = []
        const { setCollections } = useApp(this.router)

        await setCollections(useRepository())
        await this.setAsset()
        const repository = useRepository()
        this.sales = await repository.getAssetSales(this.asset.tokenId)
        this.holders = await repository.getAssetHolders(this.asset.tokenId)
        await this.setListings()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
