<template>
  <div class="floor-valuation-tag-container">
    <div class="floor-valuation-tag">
      <TrophyIcon class="trophy" />
      <div class="tag-label">
        Floor valuation
      </div>
      <div class="rank-tag">
        #{{ rank }}
      </div>
    </div>
  </div>
</template>

<script>
import TrophyIcon from '../icons/TrophyIcon.vue'

export default {
  name: 'HolderRankFloorValuationTag',
  components: {
    TrophyIcon,
  },
  props: ['rank'],
}
</script>

<style>
.floor-valuation-tag-container {
  background-color: var(--grey-one);
  padding: 5px 10px;
  border-radius: 4px;
  transition: 0.3s;
}

.floor-valuation-tag {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.trophy {
  width: 12px;
  height: 12px;
  margin: auto;
}

.tag-label {
  color: #fff;
  font-size: 0.7rem;
  font-weight: 500;
  margin: auto;
  text-transform: uppercase;
}

.rank-tag {
  font-weight: 600;
  font-size: 0.9rem;
  margin: auto;
}
</style>
