<template>
  <div
    id="section_one"
    class="section"
  >
    <div class="left">
      <div id="assets">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          fill="currentColor"
          class="bi bi-grid-3x3-gap-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
          />
        </svg>
        Assets <span class="total">{{ state.assetNumber.toLocaleString() }}</span>
      </div>

      <div id="eth">
        Ξ ETH
        <span class="price"><span class="sign">{{ getCurrencySymbol }}</span>{{ getEthInCurrency || '...' }}</span>
      </div>
      <div id="btc">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          fill="currentColor"
          class="bi bi-currency-bitcoin"
          viewBox="0 0 16 16"
        >
          <path
            d="M5.5 13v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.5v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.084c1.992 0 3.416-1.033 3.416-2.82 0-1.502-1.007-2.323-2.186-2.44v-.088c.97-.242 1.683-.974 1.683-2.19C11.997 3.93 10.847 3 9.092 3H9V1.75a.25.25 0 0 0-.25-.25h-1a.25.25 0 0 0-.25.25V3h-.573V1.75a.25.25 0 0 0-.25-.25H5.75a.25.25 0 0 0-.25.25V3l-1.998.011a.25.25 0 0 0-.25.25v.989c0 .137.11.25.248.25l.755-.005a.75.75 0 0 1 .745.75v5.505a.75.75 0 0 1-.75.75l-.748.011a.25.25 0 0 0-.25.25v1c0 .138.112.25.25.25L5.5 13zm1.427-8.513h1.719c.906 0 1.438.498 1.438 1.312 0 .871-.575 1.362-1.877 1.362h-1.28V4.487zm0 4.051h1.84c1.137 0 1.756.58 1.756 1.524 0 .953-.626 1.45-2.158 1.45H6.927V8.539z"
          />
        </svg>
        BTC
        <span class="price"><span class="sign">{{ getCurrencySymbol }}</span>{{ getBtcInCurrency || '...' }}</span>
      </div>
      <div id="xcp">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          fill="currentColor"
          class="bi bi-infinity"
          viewBox="0 0 16 16"
        >
          <path
            d="M5.68 5.792 7.345 7.75 5.681 9.708a2.75 2.75 0 1 1 0-3.916ZM8 6.978 6.416 5.113l-.014-.015a3.75 3.75 0 1 0 0 5.304l.014-.015L8 8.522l1.584 1.865.014.015a3.75 3.75 0 1 0 0-5.304l-.014.015L8 6.978Zm.656.772 1.663-1.958a2.75 2.75 0 1 1 0 3.916L8.656 7.75Z"
          />
        </svg>
        XCP
        <span class="price"><span class="sign">{{ getCurrencySymbol }}</span>{{ getXcpInCurrency || '...' }}</span>
      </div>
    </div>
    <div class="right">
      <button
        :class="getButtonClass(Tickers.USD)"
        @click="onChoseCurrency(Tickers.USD)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          fill="currentColor"
          class="bi bi-currency-dollar"
          viewBox="0 0 16 16"
        >
          <path
            d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"
          />
        </svg>
        USD
      </button>
      <button
        :class="getButtonClass(Tickers.EUR)"
        @click="onChoseCurrency(Tickers.EUR)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          fill="currentColor"
          class="bi bi-currency-euro"
          viewBox="0 0 16 16"
        >
          <path
            d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z"
          />
        </svg>
        EUR
      </button>
    </div>
  </div>
</template>

<script>
import { Tickers } from '../enums/Tickers'
import { useRouter } from 'vue-router'
import { useApp } from '../composables/useApp'
import { useRepository } from '../composables/useRepository'

export default {

  setup() {
    const router = useRouter()
    const { setAggregates, state } = useApp(router)

    setAggregates(useRepository())

    return {
      state,
      router,
    }
  },
  data: () => ({
    Tickers,
  }),

  computed: {
    getEthInCurrency() {
      const { ethInCurrency } = useApp(this.router)
      return ethInCurrency()
    },

    getBtcInCurrency() {
      const { btcInCurrency } = useApp(this.router)
      return btcInCurrency()
    },

    getXcpInCurrency() {
      const { xcpInCurrency } = useApp(this.router)
      return xcpInCurrency()
    },

    getCurrencySymbol() {
      const { selectedBaseCurrencySymbol } = useApp(this.router)
      return selectedBaseCurrencySymbol()
    },

    getSelectedCurrency() {
      return this.state.selectedCurrency
    },

    getRates() {
      const { rates } = useApp(this.router)
      return rates.value
    },
  },

  methods: {
    onChoseCurrency(currency) {
      const { setSelectedCurrency } = useApp(this.router)
      setSelectedCurrency(currency)
    },

    getButtonClass(currency) {
      return this.getSelectedCurrency === currency ? 'currency selected' : 'currency'
    },
  },
}
</script>
