import HighchartsVue from 'highcharts-vue'
import { createApp } from 'vue'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'
import 'simplebar/dist/simplebar.min.css'
import VueGtag from 'vue-gtag'

stockInit(Highcharts)
const app = createApp(App)

app.use

app.use(HighchartsVue)
app.use(VueCookies, { expires: '7d' })
app.use(VueGtag, {
  config: { id: 'G-LE2MZ6C4F3' },
  appName: '${process.env.VUE_APP_NAME}',
  pageTrackerScreenviewEnabled: true,
  params: {
    anonymize_ip: true,
  },
})

app.use(router).mount('#app')

export default app
