import BN from 'bignumber.js'

import { Tickers, TickerToDisplayDecimals, TickerToDecimals, StableCoinTickersBridge } from '../enums/Tickers'
import { usdToEur } from '../services/conversionHelpers'
import Markets from '../enums/Markets'

class Listing {
  constructor(
    {
      tokenId, //
      id,
      date,
      txUid,
      txIndex,
      txHash,
      market,
      status,
      source,
      quantity,
      price,
      ticker,
      pictures,
    },
    rates,
  ) {
    this.tokenId = tokenId
    this.id = id
    this.date = date
    this.txUid = txUid
    this.txIndex = txIndex
    this.txHash = txHash
    this.market = market
    this.status = status
    this.source = source
    this.quantity = quantity
    this.price = price
    this.ticker = ticker
    this.pictures = pictures
    this.adjustedTicker = StableCoinTickersBridge[ticker]
    this.rates = rates
    this.setConvertedPrices()
  }

  setConvertedPrices() {
    this.usdPrice = this.convertToUsd(Tickers.USD)
    const { EUR, USD } = this.rates[Tickers.BTC]
    this.eurPrice = usdToEur(this.usdPrice, USD, EUR)
  }

  convertToUsd() {
    if (this.ticker === Tickers.USD) return this.price

    if (this.adjustedTicker === Tickers.USD) {
      const decimalsToRemove = TickerToDecimals[this.ticker] - TickerToDecimals[Tickers.USD]
      return new BN(this.price).dividedBy(10 ** decimalsToRemove).toString()
    }

    if (this.adjustedTicker === Tickers.ETH) {
      const decimalsToRemove = TickerToDecimals[this.ticker]
      return new BN(this.price)
        .multipliedBy(this.rates[Tickers.ETH][Tickers.USD])
        .dividedBy(10 ** decimalsToRemove)
        .decimalPlaces(TickerToDisplayDecimals[Tickers.USD])
        .toString()
    }

    const decimalsToRemove = TickerToDecimals[this.ticker]

    return new BN(this.price)
      .multipliedBy(this.rates[this.ticker][Tickers.USD])
      .dividedBy(10 ** decimalsToRemove)
      .decimalPlaces(TickerToDisplayDecimals[Tickers.USD])
      .toString()
  }

  getUrl() {
    if ([Markets.xcp, Markets.dispenser].includes(this.market)) {
      return `https://tokenscan.io/tx/${this.txUid}`
    }

    return `https://opensea.io/assets/${this.txUid}`
  }
}

export default Listing
