<template>
  <router-link v-if="token" :to="{ name: 'Asset Detail', params: { tokenId: token.tokenId } }" target="_blank">
    <div class="token-container">
      <div class="picture-container">
        <img
          v-if="!!token.pictures"
          :src="`${apiUrl}/images/${getPictureFromPictures()}`"
          :alt="token.tokenId"
          class="token-picture"
        />
      </div>
      <div class="tokens-information">
        <div class="quantity">{{ displayQuantity }}x</div>
        <div>
          <p class="token-id">
            {{ token.tokenId }} 
          </p>
        </div>
        <div>
          <p class="token-price"><span class="price-label">Floor price: </span>{{ displayFloorPrice }}</p>
          <p class="token-price"><span class="price-label">Last sale price: </span>{{ displayLastSalePrice }}</p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import BN from '../services/BigNumber'
import { getBaseApiUrl } from '../services/AppUrls'

export default {
  props: ['token'],

  data: () => ({
    apiUrl: getBaseApiUrl(),
  }),

  computed: {
    displayQuantity() {
      return new BN(this.token.quantity).toFormat({ maximumDecimalPlaces: 2 })
    },

    displayFloorPrice() {
      return `$${new BN(this.token.floorUsdPrice).toFormat({ maximumDecimalPlaces: 2 })}`
    },

    displayLastSalePrice() {
      return `$${new BN(this.token.lastSaleUsdPrice).toFormat({ maximumDecimalPlaces: 2 })}`
    },
  },

  methods: {
    getPictureFromPictures() {
      return this.token.pictures.split(';').shift()
    },
  },
}
</script>

<style>
.token-container {
  position: relative;
  border-radius: 5px;
  padding: 1rem;
  cursor: pointer;
  background-color: var(--grey-one);
}

.token-container:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.quantity{
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--grey-four);
  font-weight: 700;
  padding: 0.5rem;
  border-radius: 4px;
}
.picture-container {
  display: grid;
  place-items: center;
  padding-bottom: 20px;
}

.token-picture {
  width: 100%;
  height: auto;
  object-fit: scale-down;
}

.tokens-information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.token-id {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-size: 1.2rem;
  flex: 1;
  margin-bottom: 1rem;
}

.token-price {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 0.5rem;
}

.token-quality {
  font-size: medium;
}

/* @TODO: Make a text-xs class, remove duplicate classes with listing table dates */
.price-label {
  font-size: 12px;
  color: #9b9b9b;
}
</style>
