import { BigNumber } from 'bignumber.js'

const FORMAT = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
}

BigNumber.config({ DECIMAL_PLACES: 3, FORMAT })

BigNumber.prototype.toFormat = (function (u) {
  const format = BigNumber.prototype.toFormat
  return function (dp, rm) {
    if (typeof dp === 'object' && dp) {
      let t = dp.minimumDecimalPlaces
      if (t !== u) return format.call(this, this.dp() < t ? t : u)
      rm = dp.roundingMode
      t = dp.maximumDecimalPlaces
      if (t !== u) return format.call(this.dp(t, rm))
      t = dp.decimalPlaces
      if (t !== u) return format.call(this, t, rm)
    }
    return format.call(this, dp, rm)
  }
})()

export default BigNumber
