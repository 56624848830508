export const Tickers = {
  ETH: 'ETH',
  BTC: 'BTC',
  EUR: 'EUR',
  USD: 'USD',
  XCP: 'XCP',
  WETH: 'WETH',
  WBTC: 'WBTC',
  USDC: 'USDC',
  DAI: 'DAI',
}

export const TickerToCent = {
  [Tickers.ETH]: 'wei',
  [Tickers.BTC]: 'sats',
}

export const TickerToSymbol = {
  [Tickers.ETH]: 'Ξ',
  [Tickers.BTC]: '₿',
  [Tickers.EUR]: '€',
  [Tickers.USD]: '$',
  [Tickers.XCP]: 'XCP',
  [Tickers.WETH]: 'WETH',
  [Tickers.WBTC]: 'WBTC',
  [Tickers.USDC]: 'USDC',
  [Tickers.DAI]: 'DAI',
}

export const TickerToDecimals = {
  [Tickers.ETH]: 18,
  [Tickers.BTC]: 8,
  [Tickers.EUR]: 2,
  [Tickers.USD]: 2,
  [Tickers.XCP]: 8,
  [Tickers.WETH]: 18,
  [Tickers.WBTC]: 8,
  [Tickers.USDC]: 6,
  [Tickers.DAI]: 18,
}

export const TickerToDisplayDecimals = {
  [Tickers.ETH]: 4,
  [Tickers.BTC]: 6,
  [Tickers.EUR]: 0,
  [Tickers.USD]: 2,
  [Tickers.XCP]: 8,
  [Tickers.WETH]: 4,
  [Tickers.WBTC]: 18,
  [Tickers.USDC]: 6,
  [Tickers.DAI]: 18,
}

export const StableCoinTickersBridge = {
  [Tickers.ETH]: Tickers.ETH,
  [Tickers.BTC]: Tickers.BTC,
  [Tickers.EUR]: Tickers.EUR,
  [Tickers.USD]: Tickers.USD,
  [Tickers.XCP]: Tickers.XCP,
  [Tickers.WETH]: Tickers.ETH,
  [Tickers.WBTC]: Tickers.BTC,
  [Tickers.USDC]: Tickers.USD,
  [Tickers.DAI]: Tickers.USD,
}
